import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  nonMedicalGestures: {
    title: "Non Medical Gestures",
    canAddNew: true,
    content: "",
    options: [
      {
        id: 1,
        checked: true,
        item: "Offering a pillow or blanket",
        key: "offering_pillow_blanket",
        alwaysChecked: true,
      },
      {
        id: 2,
        checked: true,
        item: "Asking if the patient wants water",
        key: "asking_water",
        alwaysChecked: true,
      },
      {
        id: 3,
        checked: true,
        item: "Adjusting the patient's bed for comfort",
        key: "adjusting_bed",
        alwaysChecked: true,
      },
      {
        id: 4,
        checked: true,
        item: "Offering food or drink, if appropriate",
        key: "offering_food_drink",
        alwaysChecked: true,
      },
      {
        id: 5,
        checked: true,
        item: "Asking if there's anything else they can do to improve comfort",
        key: "asking_improve_comfort",
        alwaysChecked: true,
      },
    ],
  },
  appriciationOfPT: {
    title: "Appreciation of Patient Time",
    canAddNew: true,
    content: "",
    options: [
      {
        id: 1,
        checked: true,
        item: "Recognizing that the patient has been waiting",
        key: "recognizing_wait",
        msg: "Thank you for waiting. I know it's been a little while.",
        alwaysChecked: true,
      },
      {
        id: 2,
        checked: true,
        item: "Apologizing for the delay",
        key: "apologizing_delay",
        msg: "I'm sorry for the delay.",
        alwaysChecked: true,
      },
    ],
  },
};

// // const patientSectionsSlice = createSlice({
// //   name: "sections",
// //   initialState,
// //   reducers: {
// //     setNoteTemplates: (state, action) => {
// //       console.log("ACTION PAYLOAD", action.payload);
// //       Object.entries(action.payload).forEach(([key, values]) => {
// //         console.log("KEY", key);
// //         // state[key].options = values.map((value, index) => {
// //         //   const item = Object.keys(value)[0];
// //         //   return ({
// //         //     id: index+1,
// //         //     checked: value[item],
// //         //     alwaysChecked: index%2 ? true : false,
// //         //     item,
// //         //   })
// //         // });
// //         state[key].options = values;
// //       });
// //     },
// //     setNoteTemplateOnReorder: (state, action) => {
// //       const { key, value } = action.payload;
// //       state[key].options = value;
// //     },
// //     toggleOption: (state, action) => {
// //       const { key, option, value, index } = action.payload;
// //       state[key].options[index] = { ...option, checked: value };
// //     },
// //     addNewOption: (state, action) => {
// //       const { key, option } = action.payload;
// //       state[key].options.push({
// //         checked: true,
// //         item: option,
// //         id: state[key].options.length + 2,
// //       });
// //     },
// //   },
// // });

// const patientSectionsSlice = createSlice({
//   name: "sections",
//   initialState,
//   reducers: {
//     setNoteTemplates: (state, action) => {
//       Object.entries(action.payload).forEach(([key, values]) => {
//         // Transform the incoming array data to match our expected structure
//         state[key].options = values.map((value, index) => {
//           // Check if value is already in the correct format
//           if (value.id && value.checked !== undefined && value.item) {
//             return value;
//           }

//           // If it's in the original format where value is an object with a single key
//           const item = Object.keys(value)[0];
//           return {
//             id: index + 1,
//             checked: value[item],
//             // You can modify this logic based on your requirements
//             alwaysChecked: index % 2 ? true : false,
//             item,
//           };
//         });
//       });
//     },
//     setNoteTemplateOnReorder: (state, action) => {
//       const { key, value } = action.payload;
//       state[key].options = value;
//     },
//     toggleOption: (state, action) => {
//       const { key, option, value, index } = action.payload;
//       state[key].options[index] = { ...option, checked: value };
//     },
//     addNewOption: (state, action) => {
//       const { key, option } = action.payload;
//       state[key].options.push({
//         checked: true,
//         item: option,
//         id: state[key].options.length + 2,
//       });
//     },
//   },
// });

// export const {
//   toggleOption,
//   setNoteTemplates,
//   addNewOption,
//   setNoteTemplateOnReorder,
// } = patientSectionsSlice.actions;

// export default patientSectionsSlice.reducer;

// slice issue

// const patientSectionsSlice = createSlice({
//   name: "sections",
//   initialState,
//   reducers: {
//     setNoteTemplates: (state, action) => {
//       console.log("Setting note templates with payload:", action.payload);

//       Object.entries(action.payload).forEach(([key, values]) => {
//         // Skip if the key doesn't exist in state
//         if (!state[key]) {
//           console.warn(`Warning: Key "${key}" not found in state`);
//           return;
//         }

//         // Ensure values is an array
//         if (!Array.isArray(values)) {
//           console.warn(`Warning: Values for "${key}" is not an array`);
//           return;
//         }

//         // Transform the incoming array data
//         state[key].options = values.map((value, index) => {
//           // Handle null or undefined values
//           if (!value) {
//             return {
//               id: index + 1,
//               checked: false,
//               item: "",
//             };
//           }

//           // If value is already in the correct format
//           if (value.id && value.checked !== undefined && value.item) {
//             return value;
//           }

//           // If value is an object with a single key (original format)
//           const item = Object.keys(value)[0];
//           return {
//             id: index + 1,
//             checked: Boolean(value[item]),
//             item,
//           };
//         });
//       });
//     },

//     setNoteTemplateOnReorder: (state, action) => {
//       const { key, value } = action.payload;
//       if (state[key]) {
//         state[key].options = value;
//       }
//     },

//     toggleOption: (state, action) => {
//       const { key, option, value, index } = action.payload;
//       if (state[key] && state[key].options[index]) {
//         state[key].options[index] = { ...option, checked: value };
//       }
//     },

//     addNewOption: (state, action) => {
//       const { key, option } = action.payload;
//       if (state[key]) {
//         state[key].options.push({
//           checked: true,
//           item: option,
//           id: (state[key].options.length + 1).toString(),
//         });
//       }
//     },
//   },
// });

// export const {
//   toggleOption,
//   setNoteTemplates,
//   addNewOption,
//   setNoteTemplateOnReorder,
// } = patientSectionsSlice.actions;

// export default patientSectionsSlice.reducer;

// const patientSectionsSlice = createSlice({
//   name: "sections",
//   initialState,
//   reducers: {
//     setNoteTemplates: (state, action) => {
//       console.log("Setting note templates with payload:", action.payload);

//       // Loop through each key in the payload
//       Object.entries(action.payload).forEach(([key, values]) => {
//         // Check if this section exists in our state
//         if (!state[key]) {
//           console.warn(`Warning: Section "${key}" not found in state`);
//           return;
//         }

//         // Preserve existing section properties
//         state[key] = {
//           ...state[key], // Keep title, canAddNew, etc.
//           options: Array.isArray(values)
//             ? values.map((value, index) => {
//                 // Handle if value is null or undefined
//                 if (!value) {
//                   return {
//                     id: String(index + 1),
//                     checked: false,
//                     item: "",
//                   };
//                 }

//                 // If value is already in the correct format
//                 if (value.id && value.checked !== undefined && value.item) {
//                   return value;
//                 }

//                 // If value is an object with a single key (original format)
//                 const item = Object.keys(value)[0];
//                 return {
//                   id: String(index + 1),
//                   checked: Boolean(value[item]),
//                   item,
//                 };
//               })
//             : [],
//         };
//       });
//     },

//     setNoteTemplateOnReorder: (state, action) => {
//       const { key, value } = action.payload;
//       if (state[key]) {
//         state[key].options = value;
//       }
//     },

//     toggleOption: (state, action) => {
//       const { key, option, value, index } = action.payload;
//       if (state[key]?.options?.[index]) {
//         state[key].options[index] = { ...option, checked: value };
//       }
//     },

//     addNewOption: (state, action) => {
//       const { key, option } = action.payload;
//       if (state[key]) {
//         state[key].options.push({
//           id: String(state[key].options.length + 1),
//           checked: true,
//           item: option,
//         });
//       }
//     },
//   },
// });

// export const {
//   toggleOption,
//   setNoteTemplates,
//   addNewOption,
//   setNoteTemplateOnReorder,
// } = patientSectionsSlice.actions;

// export default patientSectionsSlice.reducer;

const patientSectionsSlice = createSlice({
  name: "sections",
  initialState,
  reducers: {
    setNoteTemplates: (state, action) => {
      console.log("Setting note templates with payload:", action.payload);

      if (!action.payload) {
        console.error("Payload is undefined");
        return state;
      }

      // Create a safe copy of the state
      const newState = { ...state };

      // Loop through each key in the payload
      Object.entries(action.payload).forEach(([key, values]) => {
        // Only process if the key exists in our state
        if (newState[key]) {
          // Ensure we're working with an array
          const optionsArray = Array.isArray(values) ? values : [];

          // Update the section while preserving its structure
          newState[key] = {
            ...newState[key],
            options: optionsArray.map((value, index) => {
              // Handle null/undefined values
              if (!value) {
                return {
                  id: String(index + 1),
                  checked: false,
                  item: "",
                };
              }

              // If value is already in the correct format
              if (
                typeof value === "object" &&
                "id" in value &&
                "checked" in value &&
                "item" in value
              ) {
                return {
                  ...value,
                  id: String(value.id), // Ensure ID is a string
                };
              }

              // If value is an object with a single key (original format)
              const entries = Object.entries(value);
              if (entries.length === 1) {
                const [item, checked] = entries[0];
                return {
                  id: String(index + 1),
                  checked: Boolean(checked),
                  item: item,
                };
              }

              // Fallback for unexpected formats
              return {
                id: String(index + 1),
                checked: false,
                item: String(value),
              };
            }),
          };
        }
      });

      return newState;
    },

    setNoteTemplateOnReorder: (state, action) => {
      const { key, value } = action.payload;
      if (state[key]) {
        return {
          ...state,
          [key]: {
            ...state[key],
            options: value,
          },
        };
      }
      return state;
    },

    toggleOption: (state, action) => {
      const { key, option, value, index } = action.payload;
      if (state[key] && state[key].options && state[key].options[index]) {
        const newState = { ...state };
        newState[key] = {
          ...newState[key],
          options: [...newState[key].options],
        };
        newState[key].options[index] = { ...option, checked: value };
        return newState;
      }
      return state;
    },

    addNewOption: (state, action) => {
      const { key, option } = action.payload;
      if (state[key]) {
        return {
          ...state,
          [key]: {
            ...state[key],
            options: [
              ...state[key].options,
              {
                id: String(state[key].options.length + 1),
                checked: true,
                item: option,
              },
            ],
          },
        };
      }
      return state;
    },
  },
});

export const {
  toggleOption,
  setNoteTemplates,
  addNewOption,
  setNoteTemplateOnReorder,
} = patientSectionsSlice.actions;

export default patientSectionsSlice.reducer;
