import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import SideBar from "../../components/SideBar";
import { Box } from "@mui/material";
import { sideBarWidth } from "../../utils";
import { Outlet, useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import useApiRequest from "../../hooks/useHandleRequests";
import { useDispatch } from "react-redux";
import { setPhrases } from "../../slices/customPhraseSlice";

const Layout = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [showScreen, setShowScreen] = useState(false);
  const [version, setversion] = useState(null);
  const [softVersion, setSoftVersion] = useState(null);
  const [userMeta, setUserMeta] = useState({ initialValue: 0 });
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [isLoading, setIsLoading] = useState(true);
  const [isVLoading, setIsVLoading] = useState(true);
  const [isSVLoading, setIsSVLoading] = useState(true);

  const [error, isError] = useState(false);

  const { apiRequest: getMetaData } = useApiRequest({
    handleError: (err) => console.log(err),
    handleResponse: (data) => {
      setUserMeta(data.user);
      console.log("AUTH-0 Data", data.user.metadata);
      setUserMeta(data.user.metadata);
    },
  });

  useEffect(() => {
    const getUserMetadata = async () => {
      try {
        setIsLoading(true);
        await getMetaData(`/v3/user`);
        await getSoftVersion("/v3/user/soft-version");
        await fetchVersion("/v3/user/version");
        setIsLoading(false);
      } catch (error) {
        console.error("Error:", error);
        isError(true);
      }
    };

    getUserMetadata();
  }, []);

  // useEffect(() => {
  //   console.log("Updated User Metadata:", userMeta);
  // }, [userMeta]);

  const { apiRequest: getApiRequest } = useApiRequest({
    handleResponse: (data) => dispatch(setPhrases(data)),
    handleError: () => dispatch(setPhrases([])),
    showSuccessSnackbar: false,
  });

  const { apiRequest: fetchVersion, loading: fetchingVersion } = useApiRequest({
    handleError: (err) => console.log(err),
    handleResponse: (data) => {
      setversion(data?.body?.version || null);
    },
    showSuccessSnackbar: false,
  });

  const { apiRequest: getSoftVersion, loading: loadingSoftVersion } =
    useApiRequest({
      handleResponse: (data) => {
        setSoftVersion(data.body.softVersion);
      },
      handleError: (err) => {
        console.log(err);
      },
      showSuccessSnackbar: false,
    });
  const { apiRequest: postSoftVersion, loading: postingSoftVersion } =
    useApiRequest({
      handleResponse: () => {},
      handleError: () => {},
      successMessage: "SoftVersion has been updated",
    });

  // useEffect(() => {
  //   setIsSVLoading(true);
  //   getSoftVersion("/v3/user/soft-version");
  //   setIsSVLoading(false);
  // }, []);

  // useEffect(() => {
  //   postSoftVersion("/v3/user/soft-version", "put");
  // }, [softVersion]);

  // useEffect(() => {
  //   if (!version) {
  //     setIsVLoading(true);
  //     fetchVersion("/v3/user/version");
  //     setIsVLoading(false);
  //   } else {
  //     getApiRequest("/v3/phrase", "get", {});
  //   }

  console.log("META META", userMeta);
  useEffect(() => {
    if (isLoading === false) {
      if (
        userMeta === undefined ||
        userMeta === null ||
        Object.keys(userMeta).length === 0 ||
        version !== userMeta.version
      ) {
        navigate("/on-boarding");
      } else if (userMeta.softVersion !== softVersion) {
        postSoftVersion("/v3/user/soft-version", "put");
      } else if (
        ((window.webkit &&
          window.webkit.messageHandlers &&
          window.webkit.messageHandlers.tokenHandler) ||
          (window.Android && window.Android.handleToken)) &&
        version
      ) {
        console.log("Opened in WebView");
        navigate("/auth-token");
      } else {
        console.log("Opened in Browser");
        // navigate("/all-notes");
      }
      setShowScreen(true);
    }
  }, [version, userMeta, isAuthenticated, isLoading]);

  return (
    <Box display="flex" justifyContent="flex-end">
      <Header
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        setMobileOpen={setMobileOpen}
      />
      <SideBar
        drawerOpen={drawerOpen}
        mobileOpen={mobileOpen}
        setMobileOpen={setMobileOpen}
      />
      <Box
        component="main"
        sx={{
          marginTop: "90px",
          padding: "15px",
          width: {
            md: `calc(100% - ${drawerOpen ? sideBarWidth + 50 : 0}px)`,
            sm: "100%",
            xs: "100%",
          },
          transition: "0.2s ease-in-out",
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};

export default Layout;
