import { Add } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Grid2,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Phrases from "./Phrases";
import CustomPhraseForm from "./CreatePhraseForm";
import { useDispatch, useSelector } from "react-redux";
import {
  addPhrase,
  deletePhrase,
  editPhrase,
  setPhrases,
} from "../../slices/customPhraseSlice";
import { v4 as uuid } from "uuid";
import useApiRequest from "../../hooks/useHandleRequests";
import { enqueueSnackbar } from "notistack";

const CustomPhrases = () => {
  const dispatch = useDispatch();
  const { phrases, loading } = useSelector((state) => state.customPhrases);
  const [selectedPhrase, setSelectedPhrase] = useState(null);
  const [isCreating, setisCreating] = useState(false);

  const { apiRequest: createApiRequest } = useApiRequest({
    handleResponse: (data) => {
      dispatch(addPhrase(data));
    },
    handleError: (err) => {},
    successMessage: "Custom Phrase created",
  });

  const { apiRequest: updateApiRequest } = useApiRequest({
    handleResponse: (data) => {
      dispatch(editPhrase(data));
    },
    handleError: () => {},
    successMessage: "Custom Phrase edited successfully",
  });
  const { apiRequest: deleteApiRequest } = useApiRequest({
    handleResponse: () => {},
    handleError: () => {},
    successMessage: "Custom Phrase Deleted Successfully",
  });

  const startCreating = () => {
    setSelectedPhrase({
      code: "",
      title: "",
      content: "",
    });
    setisCreating(true);
  };

  return (
    <React.Fragment>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography
          id="customPhrases"
          fontSize="32px"
          fontWeight={700}
          fontFamily="sans-serif"
          color="#202224"
        >
          Custom Phrases
        </Typography>
        <Button
          id="addCustomPhrases"
          onClick={startCreating}
          variant="contained"
          size="large"
          color="primary"
          startIcon={<Add />}
        >
          New Custom Phrase
        </Button>
      </Box>
      <Grid2 container spacing={3}>
        <Grid2 size={{ md: 5, sm: 3, xs: 12 }}>
          <Box
            padding="16px"
            border="1px solid #2E6FF3"
            borderRadius="8px"
            margin="20px 0px"
            bgcolor="#EDF2FF"
            height="300px"
          >
            {phrases.length <= 0 ? (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                width="100%"
                height="100%"
              >
                {loading ? (
                  <CircularProgress />
                ) : (
                  <Typography
                    id="addEditCustomPhrases"
                    fontSize="16px"
                    fontWeight={500}
                    fontFamily="sans-serif"
                    color="#202224"
                  >
                    You have no custom phrases yet.
                  </Typography>
                )}
              </Box>
            ) : (
              <Phrases
                phrases={phrases}
                onSelect={(id) => {
                  setSelectedPhrase(
                    phrases.find((item) => item.id === id) || null
                  );
                  setisCreating(false);
                }}
                onDelete={async (id) => {
                  await deleteApiRequest(`/v3/phrase/${id}`, "delete");
                  dispatch(deletePhrase(id));
                  setSelectedPhrase(null);
                  setisCreating(false);
                }}
              />
            )}
          </Box>
        </Grid2>
        <Grid2 size={{ md: 7, sm: 9, xs: 12 }}>
          <Box
            padding="16px"
            border="1px solid #2E6FF3"
            borderRadius="8px"
            margin="20px 0px"
            bgcolor="#EDF2FF"
            height="300px"
          >
            {selectedPhrase === null ? (
              <Box
                id="update"
                display="flex"
                alignItems="center"
                justifyContent="center"
                width="100%"
                height="100%"
              >
                <Typography
                  fontSize="16px"
                  fontWeight={500}
                  fontFamily="sans-serif"
                  color="#202224"
                >
                  You can create your own shortcuts to quickly add repetitive or
                  frequently used information in your note. You can quickly copy
                  them in the summary and MDM.
                </Typography>
              </Box>
            ) : (
              <CustomPhraseForm
                isCreating={isCreating}
                selectedPhrase={selectedPhrase}
                onSave={(values) => {
                  console.log(values);
                  if (isCreating)
                    createApiRequest("/v3/phrase", "post", values);
                  else
                    updateApiRequest(`/v3/phrase/${selectedPhrase.id}`, "put", {
                      title: values.title,
                      code: values.code,
                      content: values.content,
                    });
                  setSelectedPhrase(null);
                  setisCreating(false);
                }}
              />
            )}
          </Box>
        </Grid2>
      </Grid2>
    </React.Fragment>
  );
};

export default CustomPhrases;
