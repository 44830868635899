import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Button, Box } from "@mui/material";

const Login = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <Box
    >
      <Button
        variant="contained"
        color="primary"
        onClick={() => loginWithRedirect()}
        sx={{
          padding: "10px 20px",
          height: "35px",
          fontSize: "16px",
          backgroundColor: "#FE4D66",
          fontWeight: "bold",
          borderRadius: "30px",
          textTransform: "none",
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)",
          ":hover": {
            backgroundColor: "#3b82f6",
            boxShadow: "0 6px 16px rgba(0, 0, 0, 0.3)",
          },
        }}
      >
        Log In
      </Button>
    </Box>
  );
};

export default Login;
