// src/AuthProvider.js
import React from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();

  const domain = "dev-bfsjddvkdu5tp867.us.auth0.com";
  const clientId = "4uGA1KA4K3b2c5Srggr7EV2pxfjFNpW1";

  const onRedirectCallback = (appState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      // domain={domain}
      // clientId={clientId}
      // authorizationParams={{
      //   redirect_uri: window.location.origin,
      //   audience: `https://${domain}/api/v2/`,
      //   scope: "openid profile email",
      // }}
      // onRedirectCallback={onRedirectCallback}
      domain="dev-bfsjddvkdu5tp867.us.auth0.com"
      clientId="4uGA1KA4K3b2c5Srggr7EV2pxfjFNpW1"
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: "http://localhost:4000/api/v3",
        scope:
          "openid profile email read:current_user user_metadata app_metadata",
      }}
    >
      {children}
    </Auth0Provider>
  );
};

export default AuthProvider;
