import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  note_sec: {
    title: 'Notes Sections',
    canAddNew: true,
    content: 'Select the sections you want have on your notes',
    options: []
  },
  careUpdate_sec: {
    title: 'Care Update Sections',
    canAddNew: true,
    content: 'Select the sections you want have on your reassessment notes',
    options: []
  },
  disp_sec: {
    title: 'Disposition Sections',
    canAddNew: true,
    content: 'Select the sections you want have on your disposition notes',
    options: []
  }
};

const sectionsSlice = createSlice({
  name: 'sections',
  initialState,
  reducers: {
    setNoteTemplates: (state, action) =>{
      Object.entries(action.payload).forEach(([key, values]) =>{
        // state[key].options = values.map((value, index) => {
        //   const item = Object.keys(value)[0];
        //   return ({
        //     id: index+1,
        //     checked: value[item],
        //     alwaysChecked: index%2 ? true : false,
        //     item,
        //   })
        // });
        if(state[key] !== undefined){
          state[key].options = values;
        }
      })
    },
    setNoteTemplateOnReorder: (state, action) => {
      const {key, value} = action.payload;
      state[key].options = value;
    },
    toggleOption: (state, action) => {
      const {key, option, value, index} = action.payload;
      state[key].options[index] = { ...option,  checked: value}
    },
    addNewOption: (state, action) => {
      const {key, option} = action.payload;
      state[key].options.push({checked: true, item: option, id: state[key].options.length + 2});
    }
  }
});

export const { toggleOption,setNoteTemplates, addNewOption, setNoteTemplateOnReorder } = sectionsSlice.actions;

export default sectionsSlice.reducer;
