import {
  ArrowBack,
  Cancel,
  Check,
  Create,
  Download,
  Mic,
  PauseOutlined,
  Save,
  Stop,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  DialogContent,
  IconButton,
  Stack,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import {
  ageUnits,
  createNoteInitialState,
  genders,
  locationType,
  numericsLocations,
} from "../utils";
import useHandleFormik from "../hooks/usehandleFormik";
import { createNoteSchema } from "../utils/schemas";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import useApiRequest from "../hooks/useHandleRequests";
import { useDispatch } from "react-redux";
import axios from "axios";
import VoiceRecorder from "./AudioRecorder";
import { v4 as uuid } from "uuid";
import SplashScreen from "./splash/SplashScreen";

const delay = 750;

const StyledTextField = styled(TextField)(({ theme, isUpperCase }) => ({
  "&": {
    backgroundColor: "transparent",
  },
  "& input": {
    textAlign: "center",
    textTransform: isUpperCase ? "uppercase" : "default",
  },
  "& fieldset": {
    borderRadius: "16px",
  },
}));

const CreateNote = ({
  initialState,
  setInitialState,
  setDraftNotes,
  setIsCreating,
  handleClose,
  resetRecording,
  onIsSubmittingChange,
  handleDialogOpen,
}) => {
  const audioBlobRef = useRef(null);
  const [showSplashScreen, setShowSplashScreen] = React.useState(false);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [audioBlob, setAudioBlob] = React.useState(null);
  const [audioUrl, setAudioUrl] = React.useState(null);
  const {
    touched,
    errors,
    values,
    setValues,
    setFieldValue,
    getFieldProps,
    handleSubmit,
    isSubmitting,
  } = useHandleFormik(initialState, createNoteSchema, handleCreation);
  const { apiRequest: updateStatus } = useApiRequest({
    handleError: (err) => console.log(err),
    handleResponse: () => {},
    showSuccessSnackbar: false,
  });

  useEffect(() => {
    onIsSubmittingChange(isSubmitting);
  }, [isSubmitting]);

  useEffect(() => {
    handleDialogOpen(isDialogOpen);
  }, [isDialogOpen]);

  useEffect(() => {
    setValues(initialState);
  }, [initialState]);
  let retryCount = 0;
  const { apiRequest: postNote, resetResponse } = useApiRequest({
    handleResponse: async (data) => {
      const fileName = `recording_${new Date().toISOString()}.mp3`;
      const mp3File = new File([audioBlobRef.current], fileName, {
        type: "audio/mp3",
      });
      console.log(mp3File);
      const url = new URL(data.PreSigned);
      const headers = new Headers();
      const contentType = url.searchParams.get("Content-Type");
      if (contentType) {
        headers.set("Content-Type", contentType);
      }
      await updateStatus(`/v3/note/${data.Note.id}/status`, "put", {
        status: "NEW_UPLOAD_STARTED",
      });
      try {
        const response = await fetch(url, {
          method: "PUT",
          body: mp3File,
          headers: headers,
        });
        await updateStatus(`/v3/note/${data.Note.id}/status`, "put", {
          status: "NEW_UPLOADED",
        });
      } catch (err) {
        console.log(err);
      }
      setShowSplashScreen(true);
    },
    handleError: (error) => {
      if (retryCount < 2) {
        retryCount += 1;
        handleCreation();
      } else {
        console.log("Max retry attempts reached. Note creation failed.");
        setShowSplashScreen(false);
        setDialogOpen(true);
      }
    },
    successMessage: "Note Has Been Created",
    showErrorSnackBar: false,
  });

  async function handleCreation() {
    await postNote("/v3/note", "post", {
      ...values,
      innitials: values?.innitials?.toUpperCase() || "",
      id: undefined,
      noteType: "note",
    });
    setDraftNotes((drafts) => drafts.filter((draft) => draft.id !== values.id));
    setInitialState(createNoteInitialState);
  }

  const saveRecording = (blob) => {
    setAudioBlob(blob);
    audioBlobRef.current = blob;
    if (blob !== null) {
      const audioURL = URL.createObjectURL(blob);
      setAudioUrl(audioURL);
      handleSubmit();
    } else {
      setAudioUrl(blob);
    }
  };

  useEffect(() => {
    let handler;
    if (showSplashScreen) {
      handler = setTimeout(() => {
        // setIsCreating(false);
        handleClose();
        setShowSplashScreen(false);
      }, delay);
    }
    return () => {
      if (handler) {
        clearTimeout(handler);
      }
    };
  }, [showSplashScreen]);
  return (
    <>
      <Box
        id="createNoteHeader"
        padding="10px"
        component="div"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <IconButton
          onClick={() => {
            if (!isSubmitting) {
              setInitialState(createNoteInitialState);
              handleClose();
            }
          }}
          size="large"
          color="primary"
        >
          <ArrowBack />
        </IconButton>
        <Typography color="#2E6FF3" fontWeight={700} fontSize="32px">
          Create Note
        </Typography>
        <Typography />
      </Box>
      {showSplashScreen ? (
        <SplashScreen />
      ) : (
        <DialogContent
          sx={{
            height: "600px",
          }}
        >
          <Box id="sex" component="div" padding="5px 0px">
            <Typography
              component="p"
              color="#444"
              fontSize="18px"
              fontWeight={500}
            >
              Sex
            </Typography>
            <Stack direction="row" gap={2}>
              {genders.map((gender) => (
                <Chip
                  disabled={isSubmitting}
                  sx={{
                    width: "100%",
                    fontSize: "1rem",
                    padding: "20px 0px",
                    borderColor:
                      touched.sex && errors.sex ? "#d32f2f" : "default",
                  }}
                  variant={gender.value === values.sex ? "filled" : "outlined"}
                  label={gender.label}
                  color={gender.value === values.sex ? "primary" : "default"}
                  onClick={() => setFieldValue("sex", gender.value)}
                />
              ))}
            </Stack>
          </Box>
          <Box id="age" component="div" padding="5px 0px">
            <Typography
              component="p"
              color="#444"
              fontSize="18px"
              fontWeight={500}
            >
              Age
            </Typography>
            <Stack direction="row" gap={2} alignItems="center">
              <StyledTextField
                type="number"
                sx={{ width: "100%" }}
                margin="dense"
                placeholder="Type Here"
                size="small"
                {...getFieldProps("age")}
                disabled={isSubmitting}
                error={touched.age && errors.age}
              />
              {ageUnits.map((ageUnit) => (
                <Chip
                  disabled={isSubmitting}
                  sx={{
                    width: "100%",
                    fontSize: "1rem",
                    padding: "20px 0px",
                    borderColor:
                      touched.ageType && errors.ageType ? "#d32f2f" : "default",
                  }}
                  variant={
                    ageUnit.value === values.ageType ? "filled" : "outlined"
                  }
                  label={ageUnit.label}
                  color={
                    ageUnit.value === values.ageType ? "primary" : "default"
                  }
                  onClick={() => setFieldValue("ageType", ageUnit.value)}
                />
              ))}
            </Stack>
          </Box>
          <Box id="patientName" component="div" padding="5px 0px">
            <Typography
              component="p"
              color="#444"
              fontSize="18px"
              fontWeight={500}
            >
              Patient Name Initials
            </Typography>
            <StyledTextField
              error={errors.innitials}
              margin="dense"
              isUpperCase
              placeholder="Type Here"
              fullWidth
              size="small"
              slotProps={{
                htmlInput: {
                  maxLength: 2,
                },
              }}
              {...getFieldProps("innitials")}
              disabled={isSubmitting}
            />
          </Box>
          <Box id="location" component="div" padding="5px 0px">
            <Typography
              component="p"
              color="#444"
              fontSize="18px"
              fontWeight={500}
            >
              Location
            </Typography>
            <Box
              display="flex"
              flexWrap="wrap"
              justifyContent="space-between"
              gap={2}
              alignItems="center"
            >
              {locationType.map((location) => (
                <Chip
                  disabled={isSubmitting}
                  sx={{
                    width: "48%",
                    fontSize: "1rem",
                    padding: "20px 0px",
                    borderColor:
                      touched.locationType && errors.locationType
                        ? "#d32f2f"
                        : "default",
                  }}
                  variant={
                    location.value === values.locationType
                      ? "filled"
                      : "outlined"
                  }
                  label={location.label}
                  color={
                    location.value === values.locationType
                      ? "primary"
                      : "default"
                  }
                  onClick={() => setFieldValue("locationType", location.value)}
                />
              ))}
            </Box>
            <StyledTextField
              type={
                numericsLocations.includes(values.locationType)
                  ? "number"
                  : "text"
              }
              sx={{ width: "100%" }}
              margin="dense"
              placeholder="Type Here"
              size="small"
              {...getFieldProps("location")}
              disabled={isSubmitting}
              error={errors.location}
            />
          </Box>
          {/* <Stack direction="row-reverse">
                <Button disabled={isSubmitting} onClick={()=>{
                  setDraftNotes(drafts => [...drafts, {...values, noteType: 'Drafts', patient: values, createdAt: Date.now(), id: uuid()}])
                  setIsCreating(false);
                }} sx={{ borderRadius: '16px'}} variant='outlined' size='medium' endIcon={<Download />} color='primary'>
                Save Draft
                </Button>
              </Stack> */}
          <Box id="recorder" component="div" marginTop="40px">
            {isSubmitting ? (
              <Stack gap={3} alignItems="center" justifyContent="center">
                <CircularProgress />
              </Stack>
            ) : (
              <VoiceRecorder
                resetRecording={resetRecording}
                disabled={isSubmitting}
                type={"createNote"}
                saveRecording={(blob) => saveRecording(blob)}
              />
            )}
            {/* <Stack justifyContent="center" margin="15px 0px" alignItems="center">
            {audioUrl && <audio controls controlsList="nodownload noplaybackrate" style={{ width: '100%'}} src={audioUrl} />}
            </Stack> */}
            {/* <Stack direction="row" alignItems=" center" justifyContent="center" gap={2} margin="15px 0px">
              <Button variant='outlined' onClick={()=> {
                setAudioBlob(null)
                setAudioUrl(null)
              }} disabled={!audioUrl}  startIcon={<Cancel />} />
              <Button onClick={handleSubmit} disabled={!audioUrl} variant='contained' startIcon={<Check />} />
            </Stack> */}
            {/* <Stack component="div" justifyContent="center" alignItems="center">
            <Button sx={{ paddingRight: '45px', paddingLeft: '45px'}} startIcon={<Save />} variant='contained' disabled={!audioBlob || isSubmitting} onClick={handleSubmit}>
              Create
            </Button>
            </Stack> */}
          </Box>
        </DialogContent>
      )}
    </>
  );
};

export default CreateNote;
