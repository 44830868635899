// src/store/index.js
import { configureStore } from "@reduxjs/toolkit";
import notesReducer from "./slices/notesSlice";
import customPhraseReducer from "./slices/customPhraseSlice";
import customNotesReducer from "./slices/customNotes";
import noteDetailsReducer from "./slices/noteDetialsSlice";
import clinicalToolReducer from "./slices/clinicalToolSlice";
import tutorialReducer from "./slices/tutorialSlice";
import patientSectionsReducer from "./slices/customPatient";

const store = configureStore({
  reducer: {
    notes: notesReducer,
    customPhrases: customPhraseReducer,
    noteTemplates: customNotesReducer,

    patientTemplates: patientSectionsReducer,

    noteDetails: noteDetailsReducer,
    clinicalTool: clinicalToolReducer,
    tutorial: tutorialReducer,
  },
});

export default store;
