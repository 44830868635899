// import React from "react";
// import { UserButton } from "@clerk/clerk-react";

// const CustomUserButton = ({ showName = false }) => {
//   return (
//     <>
//       <UserButton showName={showName} signInUrl="/all-notes" />
//     </>
//   );
// };

// export default CustomUserButton;

import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { LogOut, User } from "lucide-react";

const CustomUserButton = ({ showName = false }) => {
  const { user, logout, isAuthenticated } = useAuth0();

  const logoutWithRedirect = () =>
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });

  if (!isAuthenticated) {
    return null;
  }

  return (
    <div className="relative inline-block">
      <button
        className="flex items-center space-x-2 rounded-full bg-white p-2 shadow-sm hover:bg-gray-50 border border-gray-200"
        onClick={() => logoutWithRedirect()}
      >
        {
          <div className="h-8 w-8 rounded-full bg-gray-200 flex items-center justify-center">
            <User className="h-4 w-4 text-gray-500" />
          </div>
        }
        {showName && user?.name && (
          <span className="text-sm font-medium text-gray-700">{user.name}</span>
        )}
        <LogOut className="h-4 w-4 text-gray-500" />
      </button>
    </div>
  );
};

export default CustomUserButton;
