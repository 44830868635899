import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';

const UnderDevelopment = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#f5f5f5',
        flexDirection: 'column',
      }}
    >
      <Typography
        variant="h4"
        sx={{
          fontWeight: 700,
          color: '#2E6FF3',
          mb: 2,
          textAlign: 'center',
        }}
      >
        🚧 Page Under Development 🚧
      </Typography>
      <Typography
        variant="h6"
        sx={{
          color: '#666',
          textAlign: 'center',
          mb: 4,
        }}
      >
        We're working hard to bring you something great! This page is currently under development.
      </Typography>
      <Button
        variant="outlined"
        color="primary"
        sx={{
          textTransform: 'none',
          mb: 2,
        }}
        component={Link}
        to="/" // Modify this to route to the appropriate page
      >
        Go Back to Home
      </Button>
      <Typography
        variant="body2"
        sx={{
          color: '#888',
          textAlign: 'center',
        }}
      >
        Thank you for your patience!
      </Typography>
    </Box>
  );
};

export default UnderDevelopment;
