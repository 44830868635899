import {
  Box,
  Button,
  Card as MCard,
  CardContent,
  Pagination,
  Skeleton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Link,
  Checkbox,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { driver } from "driver.js";
import "driver.js/dist/driver.css";
import * as XLSX from "xlsx";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

import {
  setCurrentPage,
} from "../../slices/notesSlice";
import { closeTutorial } from "../../slices/tutorialSlice";
import useApiRequest from "../../hooks/useHandleRequests";
import PatientInformationDialog from "../../components/dialogs/PatientInformationDialog";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "rgba(46, 111, 243, 0.1)",
  },
  "&:hover": {
    cursor: "pointer",
    backgroundColor: "rgba(46, 111, 243, 0.2)",
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  "&": {
    color: "#202224",
    fontSize: "12px",
    fontWeight: 600,
  },
}));

const StyledPagination = styled(Pagination)(() => ({
  "&": {
    margin: "15px 0px",
  },
  "& ul": {
    justifyContent: "center",
  },
}));

const Reports = ({ tourSteps }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const { notes, filters, pagination } = useSelector((state) => state.notes);
  const isTutorialOpen = useSelector((state) => state.tutorial.isTutorialOpen);
  const [isAllNotesPage, setIsAllNotesPage] = useState(false);
  const [fromDate, setFromDate] = useState(dayjs().startOf("month"));
  const [toDate, setToDate] = useState(dayjs().endOf("month"));
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [tour, setTour] = useState(false);
  const [analytics, setAnalytics] = useState({ data: {} });

  const handleHeaderCheckboxChange = (event) => {
    const checked = event.target.checked;
    setSelectAll(checked);
    if (checked) {
      // Select all rows
      setSelectedRows(Object.keys(analytics?.data || {}));
    } else {
      // Deselect all rows
      setSelectedRows([]);
    }
  };

  const handleRowCheckboxChange = (event, userId) => {
    const checked = event.target.checked;
    setSelectedRows((prevSelectedRows) =>
      checked ? [...prevSelectedRows, userId] : prevSelectedRows.filter((id) => id !== userId)
    );
  };

  useEffect(() => {
    const match = location.pathname === "/all-notes";
    setIsAllNotesPage(match);
  }, [location]);

  const { apiRequest, loading } = useApiRequest({
    handleError: () => {},
    handleResponse: (data) => {
      setAnalytics(data);
      console.log("Analytics data:", data);
    },
    showSuccessSnackbar: false,
  });

  const { apiRequest: deleteNote } = useApiRequest({
    handleError: (err) => console.log(err),
    handleResponse: () => {},
    successMessage: "Note has been deleted",
  });

  useEffect(() => {
    apiRequest(`/v3/analytics/list`);
  }, []);


  const handleClose = () => setOpenDialog(false);

  const startTour = () => {
    const driverObj = driver({
      popoverClass: "driverjs-theme",
      showProgress: true,
      animate: true,
      prevBtnText: "Back",
      nextBtnText: "Next",
      allowClose: true,
      steps: tourSteps(notes.length === 0),
      onDestroyStarted: () => {
        setTour(false);
        localStorage.setItem(`hasSeenTour_/all-notes`, "true");
        dispatch(closeTutorial());
        driverObj.destroy();
      },
    });
    driverObj.drive();
  };

  useEffect(() => {
    if (tour) {
      startTour();
    }
  }, [tour]);

  useEffect(() => {
    const tourSeen = localStorage.getItem(`hasSeenTour_/all-notes`);
    if (tourSeen === "false") {
      setTour(true);
    }
  }, []);

  useEffect(() => {
    if (isTutorialOpen && isAllNotesPage) {
      setTour(true);
    }
  }, [isTutorialOpen]);

  const handleDownload = () => {
    const tableData = Object.entries(analytics?.data || {})
      .filter(([userId]) => selectedRows.includes(userId))
      .map(([userId, userData]) => ({
        ID: userId,
        TotalNotes: userData.notes?.totalNotes || 0,
        CompletedNotes: userData.disposition?.totalNotes || 0,
        CompletionRate: calculateCompletionRate(userData),
        DispositionCount: userData.disposition?.disposition || 0,
        MDMCompleted: userData.disposition?.mdm || 0,
      }));

    const worksheet = XLSX.utils.json_to_sheet(tableData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Reports");
    XLSX.writeFile(workbook, "reports_data.xlsx");
  };

  const calculateCompletionRate = (userData) => {
    if (!userData.notes?.totalNotes) return 0;
    const percentage =
      ((userData.disposition?.totalNotes || 0) / userData.notes.totalNotes) *
      100;
    return isNaN(percentage) ? 0 : parseFloat(percentage.toFixed(2));
  };

  const ratingg = [
    "78%",
    "82%",
    "91%",
    "64%",
    "90%",
    "97%",
    "74%",
    "99%",
    "69%",
    "92%",
    "82%",
  ];

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={3}
        >
          <Box>
            <Typography
              variant="h5"
              sx={{
                fontWeight: "bold",
                fontSize: "32px",
                textAlign: "start",
                color: "#202224",
                marginBottom: "10px",
              }}
            >
              Reports
            </Typography>
            <Box display="flex" gap={2} mt={1}>
              <DatePicker
                label="From"
                value={fromDate}
                onChange={(newValue) => setFromDate(newValue)}
                renderInput={(params) => <TextField {...params} size="small" />}
              />
              <DatePicker
                label="To"
                value={toDate}
                onChange={(newValue) => setToDate(newValue)}
                renderInput={(params) => <TextField {...params} size="small" />}
              />
            </Box>
          </Box>
          <Button
            variant="contained"
            onClick={handleDownload}
            disabled = {selectedRows.length === 0}
            sx={{
              backgroundColor: "#2E6FF3",
              color: "white",
              "&:hover": {
                backgroundColor: "#1E5CC0",
              },
            }}
          >
            Download
          </Button>
        </Box>
      </LocalizationProvider>

      <MCard>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow sx={{ bgcolor: "#2E6FF3" }}>
                <TableCell>
                  <Checkbox
                    checked={selectAll}
                    onChange={handleHeaderCheckboxChange}
                    inputProps={{ "aria-label": "Select all rows" }}
                        sx={{
                          color: "#ced3d9",
                          "&.Mui-checked": {
                            color: "#00B0FF",
                          },
                        }}
                  />
                </TableCell>
                {["User ID", "Total Notes", "Completed Notes", "Completion Rate", "Disposition Count", "Patient Experience Rating", "Actions"].map(
                  (header) => (
                    <TableCell key={header}>{header}</TableCell>
                  )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {loading
                ? Array(5)
                    .fill(null)
                    .map((_, index) => (
                      <TableRow key={index}>
                        {Array(7)
                          .fill(null)
                          .map((_, cellIndex) => (
                            <TableCell key={cellIndex}>
                              <Skeleton />
                            </TableCell>
                          ))}
                      </TableRow>
                    ))
                : Object.entries(analytics?.data || {}).map(
                    ([userId, userData], index) => (
                      <StyledTableRow key={userId}>
                        <StyledTableCell>
                          <Checkbox
                            checked={selectedRows.includes(userId)}
                            onChange={(event) => handleRowCheckboxChange(event, userId)}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {userId}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {userData.notes?.totalNotes || 0}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {userData.disposition?.totalNotes || 0}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {calculateCompletionRate(userData)}%
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {userData.disposition?.disposition || 0}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {ratingg[index] || 0}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <Link
                            href="/analytics"
                            sx={{
                              color: "#2E6FF3",
                              cursor: "pointer",
                              fontWeight: 400,
                              textDecoration: "underline",
                            }}
                          >
                            View Details
                          </Link>
                        </StyledTableCell>
                      </StyledTableRow>
                    )
                  )}
            </TableBody>
          </Table>
        </TableContainer>
        <CardContent>
          <StyledPagination
            id="pagination"
            onChange={(e, value) => dispatch(setCurrentPage(value))}
            page={pagination.currentPage}
            color="primary"
            count={1}
            shape="rounded"
          />
        </CardContent>
      </MCard>

      {openDialog && (
        <PatientInformationDialog
          open={openDialog}
          handleClose={handleClose}
          data={selectedData}
        />
      )}
    </>
  );
};

export default Reports;
