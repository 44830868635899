// // import React, { useState } from "react";
// // import {
// //   ArrowLeft,
// //   Users,
// //   Settings,
// //   Activity,
// //   Plus,
// //   Search,
// //   X,
// // } from "lucide-react";
// // import {
// //   LineChart,
// //   Line,
// //   XAxis,
// //   YAxis,
// //   CartesianGrid,
// //   Tooltip,
// //   ResponsiveContainer,
// // } from "recharts";

// // const OrganizationDetails = () => {
// //   const [activeTab, setActiveTab] = useState("members");
// //   const [showAddMember, setShowAddMember] = useState(false);
// //   const [selectedMember, setSelectedMember] = useState(null);

// //   const org = {
// //     name: "Time Enterprise",
// //     id: "org_q9KpuafSNggekbJG",
// //     display_name: "Time Enterprise",
// //   };

// //   const members = [
// //     {
// //       id: 1,
// //       name: "John Doe",
// //       email: "john@acme.com",
// //       role: "Admin",
// //       lastLogin: "2024-02-28",
// //       metadata: { department: "Engineering", location: "New York" },
// //     },
// //     {
// //       id: 2,
// //       name: "Jane Smith",
// //       email: "jane@acme.com",
// //       role: "Member",
// //       lastLogin: "2024-03-01",
// //       metadata: { department: "Sales", location: "London" },
// //     },
// //   ];

// //   const activityData = [
// //     { date: "2024-02-25", logins: 45 },
// //     { date: "2024-02-26", logins: 52 },
// //     { date: "2024-02-27", logins: 49 },
// //     { date: "2024-02-28", logins: 63 },
// //     { date: "2024-02-29", logins: 58 },
// //     { date: "2024-03-01", logins: 48 },
// //     { date: "2024-03-02", logins: 51 },
// //   ];

// //   const tabs = [
// //     { id: "members", label: "Members", icon: Users },
// //     { id: "settings", label: "Settings", icon: Settings },
// //     { id: "activity", label: "Activity", icon: Activity },
// //   ];

// //   const AddMemberModal = () => (
// //     <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
// //       <div className="bg-white rounded-lg p-6 w-full max-w-md">
// //         <div className="flex justify-between items-center mb-4">
// //           <h3 className="text-lg font-semibold">Add Member</h3>
// //           <button onClick={() => setShowAddMember(false)}>
// //             <X size={20} />
// //           </button>
// //         </div>
// //         <div className="space-y-4">
// //           <div>
// //             <label className="block text-sm font-medium mb-1">Email</label>
// //             <input
// //               type="email"
// //               className="w-full px-3 py-2 border rounded-md"
// //             />
// //           </div>
// //           <div>
// //             <label className="block text-sm font-medium mb-1">Role</label>
// //             <select className="w-full px-3 py-2 border rounded-md">
// //               <option>Admin</option>
// //               <option>Member</option>
// //             </select>
// //           </div>
// //           <button className="w-full bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700">
// //             Add Member
// //           </button>
// //         </div>
// //       </div>
// //     </div>
// //   );

// //   const MembersList = () => (
// //     <div className="bg-white rounded-lg shadow-sm">
// //       <div className="p-6 border-b flex justify-between items-center">
// //         <div className="relative flex-1 max-w-md">
// //           <Search className="absolute left-3 top-2.5 text-gray-400" size={20} />
// //           <input
// //             type="text"
// //             placeholder="Search members"
// //             className="w-full pl-10 pr-4 py-2 border rounded-md"
// //           />
// //         </div>
// //         <button
// //           onClick={() => setShowAddMember(true)}
// //           className="flex items-center gap-2 bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
// //         >
// //           <Plus size={20} />
// //           Add Member
// //         </button>
// //       </div>
// //       <div className="overflow-x-auto">
// //         <table className="w-full">
// //           <thead>
// //             <tr className="bg-gray-50">
// //               <th className="text-left px-6 py-3 text-sm font-medium text-gray-500">
// //                 User
// //               </th>
// //               <th className="text-left px-6 py-3 text-sm font-medium text-gray-500">
// //                 Role
// //               </th>
// //               <th className="text-left px-6 py-3 text-sm font-medium text-gray-500">
// //                 Last Login
// //               </th>
// //             </tr>
// //           </thead>
// //           <tbody>
// //             {members.map((member) => (
// //               <tr
// //                 key={member.id}
// //                 className="border-t hover:bg-gray-50 cursor-pointer"
// //                 onClick={() => setSelectedMember(member)}
// //               >
// //                 <td className="px-6 py-4">
// //                   <div>
// //                     <div className="font-medium text-gray-900">
// //                       {member.name}
// //                     </div>
// //                     <div className="text-sm text-gray-500">{member.email}</div>
// //                   </div>
// //                 </td>
// //                 <td className="px-6 py-4 text-gray-500">{member.role}</td>
// //                 <td className="px-6 py-4 text-gray-500">{member.lastLogin}</td>
// //               </tr>
// //             ))}
// //           </tbody>
// //         </table>
// //       </div>
// //     </div>
// //   );

// //   const MemberDetails = ({ member }) => (
// //     <div className="bg-white rounded-lg shadow-sm p-6">
// //       <div className="flex justify-between items-start mb-6">
// //         <div>
// //           <h2 className="text-xl font-semibold">{member.name}</h2>
// //           <p className="text-gray-500">{member.email}</p>
// //         </div>
// //         <button
// //           onClick={() => setSelectedMember(null)}
// //           className="text-gray-400 hover:text-gray-500"
// //         >
// //           <X size={20} />
// //         </button>
// //       </div>
// //       <div className="space-y-4">
// //         <div>
// //           <h3 className="font-medium mb-2">User Metadata</h3>
// //           <div className="bg-gray-50 p-4 rounded-md">
// //             <pre className="text-sm">
// //               {JSON.stringify(member.metadata, null, 2)}
// //             </pre>
// //           </div>
// //         </div>
// //         <div>
// //           <h3 className="font-medium mb-2">Permissions</h3>
// //           <div className="text-sm text-gray-600">Role: {member.role}</div>
// //         </div>
// //       </div>
// //     </div>
// //   );

// //   const ActivityChart = () => (
// //     <div className="bg-white rounded-lg shadow-sm p-6">
// //       <h2 className="text-xl font-semibold mb-6">Login Activity</h2>
// //       <div className="h-64">
// //         <ResponsiveContainer width="100%" height="100%">
// //           <LineChart data={activityData}>
// //             <CartesianGrid strokeDasharray="3 3" />
// //             <XAxis dataKey="date" />
// //             <YAxis />
// //             <Tooltip />
// //             <Line type="monotone" dataKey="logins" stroke="#2563eb" />
// //           </LineChart>
// //         </ResponsiveContainer>
// //       </div>
// //     </div>
// //   );

// //   return (
// //     <div className="min-h-screen bg-gray-100">
// //       <div className="bg-white border-b">
// //         <div className="px-6 py-4">
// //           {/* <button className="flex items-center text-blue-600 hover:text-blue-700 mb-4">
// //             <ArrowLeft size={20} className="mr-2" />
// //             Back to Organizations
// //           </button> */}
// //           <div className="flex items-center">
// //             <div className="w-12 h-12 bg-blue-100 rounded-lg flex items-center justify-center text-blue-600 font-medium text-xl mr-4">
// //               {org.name.charAt(0).toUpperCase()}
// //             </div>
// //             <div>
// //               <h1 className="text-2xl font-semibold text-gray-800">
// //                 {org.display_name}
// //               </h1>
// //               <p className="text-gray-500">ID: {org.id}</p>
// //             </div>
// //           </div>
// //         </div>

// //         <div className="px-6 flex space-x-6 border-t">
// //           {tabs.map(({ id, label, icon: Icon }) => (
// //             <button
// //               key={id}
// //               onClick={() => setActiveTab(id)}
// //               className={`flex items-center px-4 py-4 border-b-2 ${
// //                 activeTab === id
// //                   ? "border-blue-600 text-blue-600"
// //                   : "border-transparent text-gray-500 hover:text-gray-700"
// //               }`}
// //             >
// //               <Icon size={20} className="mr-2" />
// //               {label}
// //             </button>
// //           ))}
// //         </div>
// //       </div>

// //       <div className="p-6">
// //         {activeTab === "members" &&
// //           (selectedMember ? (
// //             <MemberDetails member={selectedMember} />
// //           ) : (
// //             <MembersList />
// //           ))}
// //         {activeTab === "activity" && <ActivityChart />}
// //         {showAddMember && <AddMemberModal />}
// //       </div>
// //     </div>
// //   );
// // };

// // export default OrganizationDetails;

// import React, { useState, useEffect } from "react";
// import { Users, Settings, Activity, Plus, Search, X } from "lucide-react";
// import {
//   LineChart,
//   Line,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   ResponsiveContainer,
// } from "recharts";
// import axios from "axios";
// import { useAuth0 } from "@auth0/auth0-react";

// const API_BASE_URL = `${process.env.REACT_APP_URL}/api/v3`;
// const domain = "dev-bfsjddvkdu5tp867.us.auth0.com";

// const GetAuthHeader = async () => {
//   const { getAccessTokenSilently } = useAuth0();

//   const token = await getAccessTokenSilently({
//     audience: `https://${domain}/api/v2/`,
//     scope: "read:users write:users read:profile update:profile",
//   });
//   return { Authorization: `Bearer ${token}` };
// };

// const OrganizationDetails = async () => {
//   //   const { getAccessTokenSilently } = useAuth0();

//   //   const token = await getAccessTokenSilently({
//   //     audience: `https://${domain}/api/v2/`,
//   //     scope: "read:users write:users read:profile update:profile",
//   //   });

//   const headers = await GetAuthHeader();

//   const [activeTab, setActiveTab] = useState("members");
//   const [showAddMember, setShowAddMember] = useState(false);
//   const [selectedMember, setSelectedMember] = useState(null);
//   const [org, setOrg] = useState(null);
//   const [members, setMembers] = useState([]);
//   const [activityData, setActivityData] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [email, setEmail] = useState("");
//   const [role, setRole] = useState("Member");

//   const orgId = "org_q9KpuafSNggekbJG"; // Get this from route params

//   useEffect(() => {
//     loadOrganizationData();
//   }, []);

//   const loadOrganizationData = async () => {
//     try {
//       const [orgResponse, membersResponse, statsResponse] = await Promise.all([
//         axios.get(`${API_BASE_URL}/organizations/${orgId}`, { headers }),
//         axios.get(`${API_BASE_URL}/organizations/${orgId}/members`, {
//           headers,
//         }),
//         axios.get(`${API_BASE_URL}/organizations/${orgId}/stats`, { headers }),
//       ]);

//       setOrg(orgResponse.data);
//       setMembers(membersResponse.data.members);
//       setActivityData(statsResponse.data.daily);
//     } catch (err) {
//       setError(err.message);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleAddMember = async (e) => {
//     e.preventDefault();
//     try {
//       await axios.post(
//         `${API_BASE_URL}/organizations/${orgId}/members`,
//         {
//           email,
//           role,
//         },
//         { headers }
//       );
//       await loadOrganizationData();
//       setShowAddMember(false);
//       setEmail("");
//       setRole("Member");
//     } catch (err) {
//       setError(err.message);
//     }
//   };

//   const handleRemoveMember = async (userId) => {
//     try {
//       await axios.delete(
//         `${API_BASE_URL}/organizations/${orgId}/members/${userId}`,
//         { headers }
//       );
//       setMembers(members.filter((m) => m.user_id !== userId));
//     } catch (err) {
//       setError(err.message);
//     }
//   };

//   const tabs = [
//     { id: "members", label: "Members", icon: Users },
//     { id: "settings", label: "Settings", icon: Settings },
//     { id: "activity", label: "Activity", icon: Activity },
//   ];

//   const AddMemberModal = () => (
//     <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
//       <div className="bg-white rounded-lg p-6 w-full max-w-md">
//         <div className="flex justify-between items-center mb-4">
//           <h3 className="text-lg font-semibold">Add Member</h3>
//           <button onClick={() => setShowAddMember(false)}>
//             <X size={20} />
//           </button>
//         </div>
//         <form onSubmit={handleAddMember} className="space-y-4">
//           <div>
//             <label className="block text-sm font-medium mb-1">Email</label>
//             <input
//               type="email"
//               value={email}
//               onChange={(e) => setEmail(e.target.value)}
//               className="w-full px-3 py-2 border rounded-md"
//               required
//             />
//           </div>
//           <div>
//             <label className="block text-sm font-medium mb-1">Role</label>
//             <select
//               value={role}
//               onChange={(e) => setRole(e.target.value)}
//               className="w-full px-3 py-2 border rounded-md"
//             >
//               <option value="Admin">Admin</option>
//               <option value="Member">Member</option>
//             </select>
//           </div>
//           <button
//             type="submit"
//             className="w-full bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
//           >
//             Add Member
//           </button>
//         </form>
//       </div>
//     </div>
//   );

//   const MembersList = () => (
//     <div className="bg-white rounded-lg shadow-sm">
//       <div className="p-6 border-b flex justify-between items-center">
//         <div className="relative flex-1 max-w-md">
//           <Search className="absolute left-3 top-2.5 text-gray-400" size={20} />
//           <input
//             type="text"
//             placeholder="Search members"
//             className="w-full pl-10 pr-4 py-2 border rounded-md"
//           />
//         </div>
//         <button
//           onClick={() => setShowAddMember(true)}
//           className="flex items-center gap-2 bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
//         >
//           <Plus size={20} />
//           Add Member
//         </button>
//       </div>
//       <div className="overflow-x-auto">
//         <table className="w-full">
//           <thead>
//             <tr className="bg-gray-50">
//               <th className="text-left px-6 py-3 text-sm font-medium text-gray-500">
//                 User
//               </th>
//               <th className="text-left px-6 py-3 text-sm font-medium text-gray-500">
//                 Role
//               </th>
//               <th className="text-left px-6 py-3 text-sm font-medium text-gray-500">
//                 Last Login
//               </th>
//               <th className="text-left px-6 py-3 text-sm font-medium text-gray-500">
//                 Actions
//               </th>
//             </tr>
//           </thead>
//           <tbody>
//             {members.map((member) => (
//               <tr key={member.user_id} className="border-t hover:bg-gray-50">
//                 <td
//                   className="px-6 py-4 cursor-pointer"
//                   onClick={() => setSelectedMember(member)}
//                 >
//                   <div>
//                     <div className="font-medium text-gray-900">
//                       {member.name}
//                     </div>
//                     <div className="text-sm text-gray-500">{member.email}</div>
//                   </div>
//                 </td>
//                 <td className="px-6 py-4 text-gray-500">{member.role}</td>
//                 <td className="px-6 py-4 text-gray-500">{member.last_login}</td>
//                 <td className="px-6 py-4">
//                   <button
//                     onClick={() => handleRemoveMember(member.user_id)}
//                     className="text-red-600 hover:text-red-800"
//                   >
//                     Remove
//                   </button>
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );

//   const MemberDetails = ({ member }) => (
//     <div className="bg-white rounded-lg shadow-sm p-6">
//       <div className="flex justify-between items-start mb-6">
//         <div>
//           <h2 className="text-xl font-semibold">{member.name}</h2>
//           <p className="text-gray-500">{member.email}</p>
//         </div>
//         <button
//           onClick={() => setSelectedMember(null)}
//           className="text-gray-400 hover:text-gray-500"
//         >
//           <X size={20} />
//         </button>
//       </div>
//       <div className="space-y-4">
//         <div>
//           <h3 className="font-medium mb-2">User Metadata</h3>
//           <div className="bg-gray-50 p-4 rounded-md">
//             <pre className="text-sm">
//               {JSON.stringify(member.metadata, null, 2)}
//             </pre>
//           </div>
//         </div>
//         <div>
//           <h3 className="font-medium mb-2">Permissions</h3>
//           <div className="text-sm text-gray-600">Role: {member.role}</div>
//         </div>
//       </div>
//     </div>
//   );

//   const ActivityChart = () => (
//     <div className="bg-white rounded-lg shadow-sm p-6">
//       <h2 className="text-xl font-semibold mb-6">Login Activity</h2>
//       <div className="h-64">
//         <ResponsiveContainer width="100%" height="100%">
//           <LineChart data={activityData}>
//             <CartesianGrid strokeDasharray="3 3" />
//             <XAxis dataKey="date" />
//             <YAxis />
//             <Tooltip />
//             <Line type="monotone" dataKey="logins" stroke="#2563eb" />
//           </LineChart>
//         </ResponsiveContainer>
//       </div>
//     </div>
//   );

//   if (loading) return <div>Loading...</div>;
//   if (error) return <div>Error: {error}</div>;
//   if (!org) return <div>Organization not found</div>;

//   return (
//     <div className="min-h-screen bg-gray-100">
//       <div className="bg-white border-b">
//         <div className="px-6 py-4">
//           <div className="flex items-center">
//             <div className="w-12 h-12 bg-blue-100 rounded-lg flex items-center justify-center text-blue-600 font-medium text-xl mr-4">
//               {org.name.charAt(0).toUpperCase()}
//             </div>
//             <div>
//               <h1 className="text-2xl font-semibold text-gray-800">
//                 {org.display_name}
//               </h1>
//               <p className="text-gray-500">ID: {org.id}</p>
//             </div>
//           </div>
//         </div>

//         <div className="px-6 flex space-x-6 border-t">
//           {tabs.map(({ id, label, icon: Icon }) => (
//             <button
//               key={id}
//               onClick={() => setActiveTab(id)}
//               className={`flex items-center px-4 py-4 border-b-2 ${
//                 activeTab === id
//                   ? "border-blue-600 text-blue-600"
//                   : "border-transparent text-gray-500 hover:text-gray-700"
//               }`}
//             >
//               <Icon size={20} className="mr-2" />
//               {label}
//             </button>
//           ))}
//         </div>
//       </div>

//       <div className="p-6">
//         {activeTab === "members" &&
//           (selectedMember ? (
//             <MemberDetails member={selectedMember} />
//           ) : (
//             <MembersList />
//           ))}
//         {activeTab === "activity" && <ActivityChart />}
//         {showAddMember && <AddMemberModal />}
//       </div>
//     </div>
//   );
// };

// export default OrganizationDetails;

import React, { useState, useEffect } from "react";
import { Users, Settings, Activity, Plus, Search, X } from "lucide-react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";

const API_BASE_URL = `${process.env.REACT_APP_URL}/api/v3`;
const domain = "dev-bfsjddvkdu5tp867.us.auth0.com";

const OrganizationDetails = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [activeTab, setActiveTab] = useState("members");
  const [showAddMember, setShowAddMember] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [org, setOrg] = useState(null);
  const [members, setMembers] = useState([]);
  const [activityData, setActivityData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("Member");

  const orgId = "org_q9KpuafSNggekbJG";

  const getAuthHeader = async () => {
    const token = await getAccessTokenSilently({
      audience: `https://${domain}/api/v2/`,
      scope: "read:users write:users read:profile update:profile",
    });
    return { Authorization: `Bearer ${token}` };
  };

  const loadOrganizationData = async () => {
    try {
      const headers = await getAuthHeader();
      const [orgResponse, membersResponse, statsResponse] = await Promise.all([
        axios.get(`${API_BASE_URL}/organizations/${orgId}`, { headers }),
        axios.get(`${API_BASE_URL}/organizations/${orgId}/members`, {
          headers,
        }),
        //axios.get(`${API_BASE_URL}/organizations/${orgId}/stats`, { headers }),
      ]);

      setOrg(orgResponse);
      setMembers(membersResponse);
      setActivityData(statsResponse?.data?.daily);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleAddMember = async (e) => {
    e.preventDefault();
    try {
      const headers = await getAuthHeader();
      await axios.post(
        `${API_BASE_URL}/organizations/${orgId}/members`,
        { email, role },
        { headers }
      );
      await loadOrganizationData();
      setShowAddMember(false);
      setEmail("");
      setRole("Member");
    } catch (err) {
      setError(err.message);
    }
  };

  const handleRemoveMember = async (userId) => {
    try {
      const headers = await getAuthHeader();
      await axios.delete(
        `${API_BASE_URL}/organizations/${orgId}/members/${userId}`,
        { headers }
      );
      setMembers(members.filter((m) => m.user_id !== userId));
    } catch (err) {
      setError(err.message);
    }
  };

  useEffect(() => {
    loadOrganizationData();
  }, []);

  const tabs = [
    { id: "members", label: "Members", icon: Users },
    { id: "settings", label: "Settings", icon: Settings },
    { id: "activity", label: "Activity", icon: Activity },
  ];

  //   const AddMemberModal = () => (
  //     <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
  //       <div className="bg-white rounded-lg p-6 w-full max-w-md">
  //         <div className="flex justify-between items-center mb-4">
  //           <h3 className="text-lg font-semibold">Add Member</h3>
  //           <button onClick={() => setShowAddMember(false)}>
  //             <X size={20} />
  //           </button>
  //         </div>
  //         <form onSubmit={handleAddMember} className="space-y-4">
  //           <div>
  //             <label className="block text-sm font-medium mb-1">Email</label>
  //             <input
  //               type="email"
  //               value={email}
  //               onChange={(e) => setEmail(e.target.value)}
  //               className="w-full px-3 py-2 border rounded-md"
  //               required
  //             />
  //           </div>
  //           <div>
  //             <label className="block text-sm font-medium mb-1">Role</label>
  //             <select
  //               value={role}
  //               onChange={(e) => setRole(e.target.value)}
  //               className="w-full px-3 py-2 border rounded-md"
  //             >
  //               <option value="Admin">Admin</option>
  //               <option value="Member">Member</option>
  //             </select>
  //           </div>
  //           <button
  //             type="submit"
  //             className="w-full bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
  //           >
  //             Add Member
  //           </button>
  //         </form>
  //       </div>
  //     </div>
  //   );

  const AddMemberModal = () => {
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = async (e) => {
      e.preventDefault();
      setIsSubmitting(true);
      try {
        await handleAddMember(e);
      } finally {
        setIsSubmitting(false);
      }
    };

    return (
      <div className="fixed inset-0 overflow-y-auto" aria-modal="true">
        <div className="flex min-h-screen items-center justify-center p-4">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

          <div className="relative w-full max-w-md transform rounded-xl bg-white shadow-xl transition-all">
            <div className="p-6">
              <div className="mb-5 flex items-center justify-between">
                <h3 className="text-xl font-semibold text-gray-900">
                  Add New Member
                </h3>
                <button
                  onClick={() => setShowAddMember(false)}
                  className="rounded-full p-1 hover:bg-gray-100"
                >
                  <X size={20} className="text-gray-500" />
                </button>
              </div>

              <form onSubmit={handleSubmit} className="space-y-5">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Email Address
                  </label>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                    placeholder="member@example.com"
                    required
                    autoFocus
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Role Assignment
                  </label>
                  <select
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                  >
                    <option value="Admin">Organization Admin</option>
                    <option value="Member">Regular Member</option>
                  </select>
                </div>

                <div className="flex gap-3 pt-2">
                  <button
                    type="button"
                    onClick={() => setShowAddMember(false)}
                    className="flex-1 rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="flex-1 rounded-md bg-blue-600 px-4 py-2 text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 disabled:opacity-50"
                  >
                    {isSubmitting ? "Adding..." : "Add Member"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const MembersList = () => (
    <div className="bg-white rounded-lg shadow-sm">
      <div className="p-6 border-b flex justify-between items-center">
        <div className="relative flex-1 max-w-md">
          <Search className="absolute left-3 top-2.5 text-gray-400" size={20} />
          <input
            type="text"
            placeholder="Search members"
            className="w-full pl-10 pr-4 py-2 border rounded-md"
          />
        </div>
        <button
          onClick={() => setShowAddMember(true)}
          className="flex items-center gap-2 bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
        >
          <Plus size={20} />
          Add Member
        </button>
      </div>
      <div className="overflow-x-auto">
        <table className="w-full">
          <thead>
            <tr className="bg-gray-50">
              <th className="text-left px-6 py-3 text-sm font-medium text-gray-500">
                User
              </th>
              <th className="text-left px-6 py-3 text-sm font-medium text-gray-500">
                Role
              </th>
              <th className="text-left px-6 py-3 text-sm font-medium text-gray-500">
                Last Login
              </th>
              <th className="text-left px-6 py-3 text-sm font-medium text-gray-500">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {members?.data?.members?.map((member) => (
              <tr key={member.user_id} className="border-t hover:bg-gray-50">
                <td
                  className="px-6 py-4 cursor-pointer"
                  onClick={() => setSelectedMember(member)}
                >
                  <div>
                    <div className="font-medium text-gray-900">
                      {member.name}
                    </div>
                    <div className="text-sm text-gray-500">{member.email}</div>
                  </div>
                </td>
                <td className="px-6 py-4 text-gray-500">{member.role}</td>
                <td className="px-6 py-4 text-gray-500">{member.last_login}</td>
                <td className="px-6 py-4">
                  <button
                    onClick={() => handleRemoveMember(member.user_id)}
                    className="text-red-600 hover:text-red-800"
                  >
                    Remove
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );

  const MemberDetails = ({ member }) => (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <div className="flex justify-between items-start mb-6">
        <div>
          <h2 className="text-xl font-semibold">{member.name}</h2>
          <p className="text-gray-500">{member.email}</p>
        </div>
        <button
          onClick={() => setSelectedMember(null)}
          className="text-gray-400 hover:text-gray-500"
        >
          <X size={20} />
        </button>
      </div>
      <div className="space-y-4">
        <div>
          <h3 className="font-medium mb-2">User Metadata</h3>
          <div className="bg-gray-50 p-4 rounded-md">
            <pre className="text-sm">
              {JSON.stringify(member.metadata, null, 2)}
            </pre>
          </div>
        </div>
        <div>
          <h3 className="font-medium mb-2">Permissions</h3>
          <div className="text-sm text-gray-600">Role: {member.role}</div>
        </div>
      </div>
    </div>
  );

  const ActivityChart = () => (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <h2 className="text-xl font-semibold mb-6">Login Activity</h2>
      <div className="h-64">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={activityData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Line type="monotone" dataKey="logins" stroke="#2563eb" />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!org) return <div>Organization not found</div>;

  console.log(members);

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="bg-white border-b">
        <div className="px-6 py-4">
          <div className="flex items-center">
            <div className="w-12 h-12 bg-blue-100 rounded-lg flex items-center justify-center text-blue-600 font-medium text-xl mr-4">
              {"T"}
            </div>
            <div>
              <h1 className="text-2xl font-semibold text-gray-800">
                {org?.data?.display_name}
              </h1>
              <p className="text-gray-500">ID: {org?.data?.id}</p>
            </div>
          </div>
        </div>

        <div className="px-6 flex space-x-6 border-t">
          {tabs.map(({ id, label, icon: Icon }) => (
            <button
              key={id}
              onClick={() => setActiveTab(id)}
              className={`flex items-center px-4 py-4 border-b-2 ${
                activeTab === id
                  ? "border-blue-600 text-blue-600"
                  : "border-transparent text-gray-500 hover:text-gray-700"
              }`}
            >
              <Icon size={20} className="mr-2" />
              {label}
            </button>
          ))}
        </div>
      </div>

      <div className="p-6">
        {activeTab === "members" &&
          (selectedMember ? (
            <MemberDetails member={selectedMember} />
          ) : (
            <MembersList />
          ))}
        {activeTab === "activity" && <ActivityChart />}
        {showAddMember && <AddMemberModal />}
      </div>
    </div>
  );
};

export default OrganizationDetails;
