import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import React, { useEffect } from "react";
import CustomSelectInput from "../CustomSelectInput";
import { ageUnits, locationType, statuses } from "../../utils";
import useHandleFormik from "../../hooks/usehandleFormik";
import useApiRequest from "../../hooks/useHandleRequests";
import { patientInformationSchema } from "../../utils/schemas";
import { useDispatch } from "react-redux";
import { updatepatient } from "../../slices/notesSlice";

const StyledTextField = styled(TextField)(({ theme }) => ({
  "&": {
    margin: "15px 0px",
  },
  "& input": {
    textAlign: "center",
  },
  "& fieldset": {
    borderRadius: "8px",
    backgroundColor: "rgba(46, 111, 243, 0.1)",
  },
}));

const initialState = {
  innitials: "",
  age: null,
  ageType: "YEARS",
  sex: "MALE",
  location: null,
  locationType: "Room",
  status: "",
};

const PatientInformationDialog = ({ open, data, handleClose }) => {
  console.log(data);
  const dispatch = useDispatch();
  const {
    errors,
    values,
    setFieldValue,
    getFieldProps,
    isSubmitting,
    handleSubmit,
    resetForm,
    setValues,
  } = useHandleFormik(
    data
      ? {
          innitials: data.innitials || "",
          age: data.age || null,
          ageType: data.ageType || "YEARS",
          sex: data.sex || null,
          location: data.location || null,
          locationType: data.location || null,
          status: null,
        }
      : initialState,
    patientInformationSchema,
    savePatient
  );

  console.log(errors);

  const { apiRequest: updateLocation } = useApiRequest({
    handleError: (err) => console.log(err),
    handleResponse: (data) => console.log(data),
    showSuccessSnackbar: false,
  });

  const { apiRequest: savePatientData, loading: savingPatient } = useApiRequest(
    {
      handleError: (err) => console.log(err),
      handleResponse: async (data) => {},
      successMessage: "Patient information has been updated",
    }
  );

  async function savePatient() {
    await savePatientData(`/v3/patient/${data.id}`, "put", values);
    await updateLocation(`/v3/note/${data.noteId}`, "put", {
      location: values.location,
      locationType: values.locationType,
    });
    dispatch(
      updatepatient({
        noteId: data.noteId,
        patientData: values,
        location: values.location,
        locationType: values.locationType,
      })
    );
    handleClose();
  }

  useEffect(() => {
    setValues(data);
  }, [data]);
  const getStyles = (gender) => {
    if (values?.sex === gender)
      return {
        bgcolor: "#2E6FF3",
        color: "white",
      };
    if (values?.sex === gender)
      return {
        bgcolor: "#2E6FF3",
        color: "white",
      };
    return {
      bgcolor: "rgba(46, 111, 243, 0.1)",
      color: "#202224",
    };
  };
  return (
    <Dialog
      maxWidth="md"
      open={open}
      onClose={() => {
        handleClose();
      }}
    >
      <DialogTitle
        textAlign="center"
        color="#202224"
        fontWeight={700}
        fontSize="32px"
        margin="15px 0px"
      >
        Patient Information
      </DialogTitle>
      <DialogContent
        sx={{
          height: "600px",
        }}
      >
        <Grid container spacing={{ md: 4, sm: 2, xs: 1 }}>
          <Grid size={{ md: 6, sm: 6, xs: 12 }}>
            <Typography
              component="p"
              fontSize="16px"
              fontWeight={600}
              color="#ADADAD"
            >
              Patient Initial
            </Typography>
            <StyledTextField fullWidth {...getFieldProps("innitials")} />
          </Grid>
          <Grid size={{ md: 6, sm: 6, xs: 12 }}>
            <Typography
              component="p"
              fontSize="16px"
              fontWeight={600}
              color="#ADADAD"
            >
              Age
            </Typography>
            <Box
              component="div"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box component="div" width="35%">
                <StyledTextField fullWidth {...getFieldProps("age")} />
              </Box>
              <Box component="div" width="55%">
                <CustomSelectInput
                  options={ageUnits}
                  onSelect={(option) => setFieldValue("ageType", option.value)}
                  value={values?.ageType}
                />
              </Box>
            </Box>
          </Grid>
          <Grid size={{ md: 6, sm: 6, xs: 12 }}>
            <Typography
              component="p"
              fontSize="16px"
              fontWeight={600}
              color="#ADADAD"
            >
              Sex
            </Typography>
            <Box
              component="div"
              display="flex"
              justifyItems="flex-start"
              alignItems="center"
              gap={2}
              margin="15px 0px"
            >
              <Box
                {...getStyles("MALE")}
                onClick={() => setFieldValue("sex", "MALE")}
                sx={{ cursor: "pointer" }}
                component="div"
                borderRadius="8px"
                border="0.6px solid #D5D5D5"
                padding="15px 30px"
              >
                Male
              </Box>
              <Box
                {...getStyles("FEMALE")}
                onClick={() => setFieldValue("sex", "FEMALE")}
                sx={{ cursor: "pointer" }}
                component="div"
                borderRadius="8px"
                border="0.6px solid #D5D5D5"
                padding="15px 30px"
              >
                Female
              </Box>
            </Box>
          </Grid>
          <Grid size={{ md: 6, sm: 6, xs: 12 }}>
            <Typography
              component="p"
              fontSize="16px"
              fontWeight={600}
              color="#ADADAD"
            >
              Location
            </Typography>
            <Box
              component="div"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box component="div" width="35%">
                <StyledTextField {...getFieldProps("location")} fullWidth />
              </Box>
              <Box component="div" width="55%">
                <CustomSelectInput
                  options={locationType}
                  onSelect={(option) =>
                    setFieldValue("locationType", option.value)
                  }
                  value={values?.locationType}
                />
              </Box>
            </Box>
          </Grid>
          {/* <Grid size={{ md: 6, sm: 6, xs: 12}}>
          <Typography component="p" fontSize="16px" fontWeight={600} color='#ADADAD'>
            Status
          </Typography>
          <CustomSelectInput options={statuses} onSelect={(option) => setFieldValue('status',option)} value={values?.status} />
          </Grid> */}
        </Grid>
        <Box
          margin="15px 0px"
          component="div"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {isSubmitting ? (
            <CircularProgress />
          ) : (
            <Button
              disabled={savingPatient}
              onClick={handleSubmit}
              sx={{ width: "200px" }}
              color="primary"
              variant="contained"
            >
              Done
            </Button>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default PatientInformationDialog;
