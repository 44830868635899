import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react';



const SignedOutRouting = () => {
  const {isAuthenticated} = useAuth0();
  const navigate = useNavigate();
  useEffect(()=>{
    if(!isAuthenticated){
      navigate('/')
    }
  },[])
  return <></>
}

export default SignedOutRouting