import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  IconButton,
} from "@mui/material";
import Chart from "react-apexcharts";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import discharge from "../../assets/discharge.png";
import admit from "../../assets/admit.png";
import admission from "../../assets/admission.png";
import records from "../../assets/records.png";
import medical from "../../assets/medical.png";
import history from "../../assets/history.png";
import lab from "../../assets/lab.png";
import social from "../../assets/social.png";
import independent from "../../assets/independent.png";
import care from "../../assets/care.png";
import useApiRequest from "../../hooks/useHandleRequests";

const nameData = [
  {
    key: "additionalHistory",
    name: "Additional History obtained from source other than patient",
  },
  {
    key: "socialDeterminants",
    name: "Social Determinants Affecting Health",
  },
  {
    key: "externalRecords",
    name: "External Records Reviewed",
  },
  {
    key: "chronicMedicalConditions",
    name: "Chronic Medical Conditions Significantly Affecting Care",
  },
  {
    key: "careUpdates",
    name: "Care Discussed with other Providers",
  },
  {
    key: "pertinentLabTests",
    name: "Independent Interpretation of Labs",
  },
];

const imageMapping = {
  hpi: admit,
  careUpdates: care,
  additionalHistory: history,
  externalRecords: records,
  chronicMedicalConditions: medical,
  socialDeterminants: social,
  pertinentLabTests: lab,
  immunization: independent,
  medications: discharge,
  plan: admission,
};

const fillColor = "#2E6FF3";
const trackColor = "#cfcfcf";

const Analytics = () => {
  const [analytics, setAnalytics] = useState([]);
  const includeKeys = [
    "additionalHistory",
    "socialDeterminants",
    "externalRecords",
    "chronicMedicalConditions",
    "careUpdates",
    "pertinentLabTests",
  ];
  const [filteredAnalyticsArray, setFilteredAnalyticsArray] = useState([]);
  const [showContent, setShowContent] = useState([]);
  const { apiRequest: getAnalytics, loading: loadingNoteDetails } =
    useApiRequest({
      handleError: (err) => console.log(err),
      handleResponse: (data) => {
        setAnalytics(data);
        let combinedResults = [];
        const processAnalyticsData = (analyticsArray, source) => {
          const analyticsObject = analyticsArray[0] || {};
          const filteredArray = Object.keys(analyticsObject)
            .filter((key) => includeKeys.includes(key))
            .map((key) => ({ key, value: analyticsObject[key], source }));

          const updatedArray = filteredArray.map((item) => ({
            ...item,
            image: imageMapping[item.key] || "",
          }));

          const updatedData = updatedArray.map((item) => {
            const nameItem = nameData.find(
              (nameObj) => nameObj.key === item.key
            );
            return { ...item, name: nameItem ? nameItem.name : "" };
          });
          combinedResults = combinedResults.concat(updatedData);
        };
        if (data.body.noteAnalytics) {
          processAnalyticsData(data.body.noteAnalytics, "noteAnalytics");
        }
        if (data.body.dispAnalytcs) {
          processAnalyticsData(data.body.dispAnalytcs, "dispAnalytics");
        }
        setFilteredAnalyticsArray(combinedResults);
        setShowContent(combinedResults.map(() => true));
      },
      showSuccessSnackbar: false,
    });

  useEffect(() => {
    getAnalytics(`/v3/analytics`);
  }, []);
  const toggleContentVisibility = (index) => {
    setShowContent((prev) =>
      prev.map((visible, i) => (i === index ? !visible : visible))
    );
  };
  const percentageDivider = (item) => {
    return item.source === "dispAnalytics"
      ? analytics?.body?.dispAnalytcs[0]?.totalNotes
      : analytics?.body?.noteAnalytics[0]?.totalNotes;
  };

  return (
    <Box sx={{ px: 4, py: 1, backgroundColor: "#f5f5f5", minHeight: "100vh" }}>
      <Typography
        variant="h5"
        sx={{
          fontWeight: "bold",
          fontSize: "32px",
          textAlign: "start",
          mb: 3,
          color: "#202224",
        }}
      >
        Analytics
      </Typography>
      {/* <Typography
        variant='h5'
        sx={{
          fontWeight: 400,
          textAlign: 'start',
          fontSize: '16px',
          mb: 3,
          color: '#202224',
        }}
      >
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi.
      </Typography> */}

      <Grid container spacing={3} justifyContent="center">
        {filteredAnalyticsArray?.map((item, index) => (
          <Grid item key={index} xs={12} sm={6} md={4}>
            <Card
              sx={{
                border: "1px solid #2E6FF3",
                borderRadius: "8px",
                backgroundColor: "#EDF2FF",
                textAlign: "center",
                width: "357px",
                height: showContent[index] ? "424px" : "70px",
                position: "relative",
                transition: "height 0.3s ease",
              }}
            >
              <IconButton
                sx={{
                  color: fillColor,
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  zIndex: 1,
                }}
                onClick={() => toggleContentVisibility(index)}
              >
                {showContent[index] ? <Visibility /> : <VisibilityOff />}
              </IconButton>

              {showContent[index] && (
                <CardContent
                  sx={{
                    position: "relative",
                    padding: "20px 0px 0px 0px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    sx={{
                      height: "357px",
                    }}
                  >
                    <Box display="flex">
                      <Typography
                        variant="h6"
                        sx={{
                          fontSize: "24px",
                          fontWeight: 600,
                          lineHeight: "32.68px",
                          letterSpacing: "-0.11px",
                          pt: "30px",
                          color: fillColor,
                          textAlign: "center",
                        }}
                      >
                        {item.name}
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <Box
                        sx={{
                          position: "relative",
                          width: "200px",
                          height: "200px",
                        }}
                      >
                        <Chart
                          options={{
                            chart: {
                              type: "radialBar",
                            },
                            colors: [fillColor],
                            plotOptions: {
                              radialBar: {
                                hollow: {
                                  margin: 0,
                                  size: "70%",
                                },
                                track: {
                                  background: trackColor,
                                  strokeWidth: "100%",
                                },
                                dataLabels: {
                                  show: false,
                                },
                              },
                            },
                          }}
                          series={[
                            (item.value / percentageDivider(item)) * 100,
                          ]}
                          type="radialBar"
                          height={200}
                        />
                        <Box
                          component="img"
                          fetchPriority="high"
                          src={item.image || discharge}
                          alt={item.key}
                          sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: "140px",
                            height: "140px",
                            borderRadius: "50%",
                          }}
                        />
                      </Box>
                      <Typography
                        variant="h6"
                        sx={{
                          fontSize: "48px",
                          fontWeight: 400,
                          lineHeight: "65.37px",
                          color: fillColor,
                        }}
                      >
                        {(
                          (item.value / percentageDivider(item)) *
                          100
                        ).toLocaleString(undefined, {
                          maximumFractionDigits: 1,
                        })}
                        %
                      </Typography>
                      {/* <Typography
                        variant='h6'
                        sx={{
                          fontSize: '16px',
                          fontWeight: 400,
                          lineHeight: '21.37px',
                          color: '#7A7B7D',
                        }}
                      >
                        {item.detail || ''}
                      </Typography> */}
                    </Box>
                  </Box>
                </CardContent>
              )}
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Analytics;
