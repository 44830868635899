import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  InputAdornment,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  authSideBarList,
  clinicalDecesionTools,
  contentBody,
  getFilterSelectedNoteTypeParams,
  sideBarList,
  tourStepsCreateNote,
} from "../utils";
import {
  Logout,
  Mail,
  Mic,
  Search,
  Settings,
  WhatsApp,
  HelpOutline,
} from "@mui/icons-material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useClerk } from "@clerk/clerk-react";
import NotesDialog from "./dialogs/NotesDialog";
import { setNotes } from "../slices/notesSlice";
import useApiRequest from "../hooks/useHandleRequests";
import { useDispatch, useSelector } from "react-redux";
import { selectTool } from "../slices/clinicalToolSlice";
import { openTutorial } from "../slices/tutorialSlice";
import { driver } from "driver.js";
import "driver.js/dist/driver.css";
import { useAuth0 } from "@auth0/auth0-react";

const StyledButton = styled(Button)(({ theme, variant }) => ({
  "&": {
    fontSize: "20px",
    backgroundColor: variant === "contained" ? "#007BFF" : "inherit",
    margin: "5px 0px",
  },
}));

const SideBarContent = () => {
  const { logout } = useAuth0();
  const { loginWithRedirect } = useAuth0();

  // const handleLogin = () => {
  //   loginWithRedirect({
  //     redirect_uri: "dev-bfsjddvkdu5tp867.us.auth0.com/dashboard",
  //     organization: "org_q9KpuafSNggekbJG",
  //   });
  // };

  // const handleLogin = async () => {
  //   await loginWithRedirect({
  //     appState: {
  //       returnTo: "https://manage.auth0.com/dashboard",
  //     },
  //   });
  // };

  // const handleLogin = async () => {
  //   await loginWithRedirect({
  //     appState: {
  //       returnTo:
  //         "https://manage.auth0.com/dashboard/us/dev-bfsjddvkdu5tp867/organizations/org_q9KpuafSNggekbJG/settings",
  //     },
  //   });
  // };

  const { isAuthenticated } = useAuth0();

  // useEffect(() => {
  //   if (isAuthenticated) {
  //     window.open(
  //       "https://manage.auth0.com/dashboard/us/dev-bfsjddvkdu5tp867/organizations/org_q9KpuafSNggekbJG/settings",
  //       "_blank"
  //     );
  //   }
  // }, [isAuthenticated]);

  const handleLogin = () => {
    loginWithRedirect();
  };

  const logoutWithRedirect = () =>
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });

  // const handleLogin = () => {
  //   loginWithRedirect({
  //     onRedirectCallback: () => {
  //       window.location.href =
  //         "https://manage.auth0.com/dashboard/us/dev-bfsjddvkdu5tp867/organizations/org_q9KpuafSNggekbJG/settings";
  //     },
  //   });
  // };

  // const handleLogin = () => {
  //   loginWithRedirect({
  //     onRedirectCallback: () => {
  //       window.location.href =
  //         "https://manage.auth0.com/dashboard/us/dev-bfsjddvkdu5tp867/organizations/org_q9KpuafSNggekbJG/settings";
  //     },
  //   });
  // };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { id } = useParams();
  const isAdmin = false;
  const showClinicalToools =
    location.pathname.includes("/all-notes/") 
    // &&
    // location.pathname.split("/").length === 3 &&
    // !isNaN(+id) &&
    // isAdmin;
  const showShareOption = location.pathname === "/all-notes" && false;
  const handleTutorialClick = () => {
    dispatch(openTutorial());
  };
  const { tool: clincalTool } = useSelector((state) => state.clinicalTool);
  const [openNotes, setOpenNotes] = useState(false);
  const [tour, setTour] = useState(false);
  const [searchContent, setSearchContent] = useState("");
  const { apiRequest, loading } = useApiRequest({
    handleError: () => {},
    handleResponse: (data) => dispatch(setNotes(data)),
    showSuccessSnackbar: false,
  });
  const { apiRequest: postConfigs, loading: postingConfigs } = useApiRequest({
    handleResponse: () => {},
    handleError: () => {},
    successMessage: "Configs has been updated",
  });
  const { pagination, filters } = useSelector((state) => state.notes);

  const handleShareByEmail = () => {
    const subject = encodeURIComponent("Check out Time by Dr. Haydar!");
    const mailtoLink = `mailto:?subject=${subject}&body=${contentBody}`;
    window.location.href = mailtoLink;
  };

  const handleShareByWhatsApp = () => {
    const whatsappLink = `https://wa.me/?text=${contentBody}`;
    window.open(whatsappLink, "_blank");
  };

  const startTour = () => {
    const driverObj = driver({
      popoverClass: "driverjs-theme",
      showProgress: true,
      animate: true,
      prevBtnText: "Back",
      nextBtnText: "Next",
      allowClose: true,
      steps: tourStepsCreateNote,
      onDestroyStarted: () => {
        setTour(false);
        localStorage.setItem(`hasSeenTour_/createNote`, "true");
        driverObj.destroy();
      },
    });

    driverObj.drive();
  };
  useEffect(() => {
    if (tour) {
      startTour();
    }
  }, [tour]);
  useEffect(() => {
    const tourSeen = localStorage.getItem(`hasSeenTour_/createNote`);
    if (openNotes && tourSeen === "false") {
      setTour(true);
    }
  }, [openNotes]);

  const tempCondition = true;
  const sideBarContent = tempCondition ? authSideBarList : sideBarList();
  return (
    <Box component="div" display="flex" flexDirection="column" height="100vh">
      <Box component="div" padding="30px 0px 0px 0px" textAlign="center">
        <img src="/images/drh logo.png" height="31px" alt="Logo" />
      </Box>
      <Box component="div" padding="12px 0px" flexGrow={1}>
        <List>
          {sideBarContent.map((item, index) => {
            const itemSelected =
              `/${location.pathname.split("/")[1]}` === item.path;
            return (
              <ListItemButton
                id={`side-bar-item${index}`}
                onClick={() => navigate(item.path)}
                key={item.text}
                sx={{
                  backgroundColor: itemSelected ? "#2E6FF3" : "inherit",
                  "&.Mui-selected": {
                    backgroundColor: "#2E6FF3",
                    color: "white",
                  },
                  "&.Mui-selected:hover": {
                    backgroundColor: "#2E6FF3",
                    color: "white",
                  },
                }}
                selected={itemSelected}
              >
                <ListItemIcon
                  sx={{ color: itemSelected ? "white" : "#2E6FF3" }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{
                    color: itemSelected ? "white" : "#2E6FF3",
                  }}
                  primary={item.text}
                />
              </ListItemButton>
            );
          })}
        </List>
        {/* <Divider sx={{ margin: '30px 0px' }} />
        <Box id='createnote' component='div' padding="15px">
          <Button sx={{ fontWeight: 700 }} onClick={()=>{
            setOpenNotes(true)
            } } size='large' fullWidth startIcon={<Mic fontSize='large' />} variant='contained' color='error'>
            Create Note
          </Button>
        </Box> */}
        {showClinicalToools && (
          <Box component="div" padding="16px" marginTop="8px">
            <Typography
              id="clinical-decisions"
              component="p"
              fontFamily="sans-serif"
              fontSize="16px"
              fontWeight={600}
              color="#000000"
              textAlign="center"
            >
              Clinical Decision Tools
            </Typography>
            <TextField
              value={searchContent}
              onChange={(e) => setSearchContent(e.target.value)}
              size="small"
              fullWidth
              sx={{
                backgroundColor: "#F5F6FA",
              }}
              variant="outlined"
              placeholder="Search"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
            <Box component="div">
              {clinicalDecesionTools
                .filter((item) =>
                  item.tool.includes(searchContent.toUpperCase().trim())
                )
                .map((tool) => {
                  return (
                    <StyledButton
                      onClick={() => dispatch(selectTool(tool.tool))}
                      fullWidth
                      variant={
                        clincalTool === tool.tool ? "contained" : "outlined"
                      }
                    >
                      {tool.tool}
                    </StyledButton>
                  );
                })}
            </Box>
          </Box>
        )}
      </Box>
      {showShareOption && (
        <Card
          sx={{
            bgcolor: "rgba(46, 111, 243, 0.4)",
            textAlign: "center",
            overflow: "visible",
            margin: "0px 15px",
          }}
        >
          <img
            style={{ marginTop: "-45px" }}
            width="200px"
            src="/images/share.png"
            alt="share image"
          />
          <CardContent>
            <Stack
              gap={1}
              width="100%"
              justifyContent="center"
              alignItems="center"
              direction="row-reverse"
            >
              <Button
                onClick={handleShareByWhatsApp}
                sx={{ textTransform: "none", bgcolor: "#2E6FF3" }}
                size="small"
                variant="contained"
                startIcon={<WhatsApp />}
              >
                WhatsApp
              </Button>
              <Button
                onClick={handleShareByEmail}
                sx={{ textTransform: "none", bgcolor: "#2E6FF3" }}
                size="small"
                variant="contained"
                startIcon={<Mail />}
              >
                Mail
              </Button>
            </Stack>
          </CardContent>
        </Card>
      )}
      <Box
        component="div"
        padding="15px 0px 30px 0px"
        borderTop="0.6px solid #E0E0E0"
      >
        {process.env.SHOW_SETTINGS_BUTTON && (
          <ListItemButton>
            <ListItemIcon>
              <Settings htmlColor="#202224" />
            </ListItemIcon>
            <ListItemText primary="Settings" color="#202224" />
          </ListItemButton>
        )}
        {/* <ListItemButton onClick={handleTutorialClick}>
          <ListItemIcon>
            <HelpOutline htmlColor='#202224' />
          </ListItemIcon>
          <ListItemText primary="Tutorial" color='#202224' />
        </ListItemButton> */}
        <ListItemButton onClick={() => logoutWithRedirect()}>
          <ListItemIcon>
            <Logout htmlColor="#202224" />
          </ListItemIcon>
          <ListItemText primary="Log Out" color="#202224" />
        </ListItemButton>
        {/* Admin Panel */}
        {/* <ListItemButton onClick={handleLogin}>
          <ListItemIcon>
            <Logout htmlColor="#202224" />
          </ListItemIcon>
          <ListItemText primary="Auth 0 Dashboard" color="#202224" />
        </ListItemButton> */}
      </Box>
      <NotesDialog
        open={openNotes}
        handleClose={() => {
          apiRequest(
            `/v3/note${
              getFilterSelectedNoteTypeParams[filters.noteType]
            }&paginate=true&limit=${pagination.notesPerPage}&page=${
              pagination.currentPage
            }`,
            "get"
          );
          setOpenNotes(false);
        }}
      />
    </Box>
  );
};

export default SideBarContent;
