import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import VoiceRecorder from "../AudioRecorder";
import useApiRequest from "../../hooks/useHandleRequests";
import SplashScreen from "../splash/SplashScreen";

const delay = 750;

const UpdateNoteDialog = ({ data, type, handleClose }) => {
  const dispatch = useDispatch();
  const audioRef = useRef(null);
  const [audioUrl, setAudioUrl] = useState(null);
  const [showSplashScreen, setShowSplashScreen] = useState(false);
  const [creationLoading, setCreationLoading] = useState(false);

  const typeRef = useRef(type);

  useEffect(() => {
    if (typeRef.current !== type) {
      typeRef.current = type;
    }
  }, [type]);

  const { apiRequest: updateStatus } = useApiRequest({
    handleError: (err) => console.log(err),
    handleResponse: () => {},
    showSuccessSnackbar: false,
  });

  const [resetRecording, setResetRecording] = React.useState(false);
  const handleResetRecording = () => setResetRecording(false);

  useEffect(() => {
    if (!type) {
      setResetRecording(true);
    }
    handleResetRecording();
    return () => {
      setResetRecording(true);
      handleResetRecording();
    };
  }, [type]);

  const { apiRequest: createNote, loading: isCreating } = useApiRequest({
    handleError: (err) => console.log(err),
    handleResponse: useCallback(
      async (data) => {
        const fileName = `recording_${new Date().toISOString()}.mp3`;
        const mp3File = new File([audioRef.current], fileName, {
          type: "audio/mp3",
        });
        const url = new URL(data.PreSigned);
        const headers = new Headers();
        const contentType = url.searchParams.get("Content-Type");
        if (contentType) {
          headers.set("Content-Type", contentType);
        }
        try {
          await updateStatus(`/v3/note/${data.Note.id}/status`, "put", {
            status: typeRef.current?.startStatus,
          });
          const response = await fetch(url, {
            method: "PUT",
            body: mp3File,
            headers: headers,
          });
          await updateStatus(`/v3/note/${data.Note.id}/status`, "put", {
            status: typeRef.current?.endStatus,
          });
          setAudioUrl(null);
          audioRef.current = null;
        } catch (err) {
          console.log(err);
        } finally {
          setCreationLoading(false);
          setShowSplashScreen(true);
        }
      },
      [type]
    ),
    successMessage: "Note has been created",
  });

  useEffect(() => {
    let handler;
    if (showSplashScreen) {
      handler = setTimeout(() => {
        handleClose();
        setShowSplashScreen(false);
      }, delay);
    }
    return () => {
      if (handler) {
        clearTimeout(handler);
      }
    };
  }, [showSplashScreen]);

  return (
    <Dialog
      scroll="paper"
      maxWidth="md"
      fullWidth
      open={Boolean(type)}
      onClose={handleClose}
    >
      <DialogTitle>
        <Typography
          textAlign="center"
          color="#2E6FF3"
          fontWeight={700}
          fontSize="32px"
        >
          {type?.label}
        </Typography>
      </DialogTitle>
      {showSplashScreen ? (
        <SplashScreen />
      ) : (
        <DialogContent sx={{ height: "600px" }}>
          <Stack gap={2} marginBottom="45px">
            <Typography
              fontWeight={500}
              fontSize="36px"
              fontFamily="sans-serif"
              color="#33384B"
              textAlign="center"
            >
              {data?.patient?.innitials}
            </Typography>
            <Typography
              fontWeight={500}
              fontSize="28px"
              fontFamily="sans-serif"
              color="#33384B"
              textAlign="center"
            >
              {data?.patient?.sex} | {data?.patient?.age}{" "}
              {data?.patient?.ageType}
            </Typography>
            <Typography
              fontWeight={500}
              fontSize="28px"
              fontFamily="sans-serif"
              color="#33384B"
              textAlign="center"
            >
              {data?.locationType} {data?.location}
            </Typography>
          </Stack>
          {creationLoading ? (
            <Stack gap={3} alignItems="center" justifyContent="center">
              <CircularProgress />
            </Stack>
          ) : (
            <VoiceRecorder
              resetRecording={resetRecording}
              type={type?.label}
              saveRecording={(blob) => {
                audioRef.current = blob;
                if (blob !== null) {
                  const audioURL = URL.createObjectURL(blob);
                  setAudioUrl(audioURL);
                  createNote("/v3/note", "post", {
                    ...data.patient,
                    parentNote: data.id,
                    // locationType: data.note.locationType,
                    noteType: type.noteType,
                    status: type.startStatus,
                  });
                  setCreationLoading(true);
                } else {
                  setAudioUrl(blob);
                }
              }}
            />
          )}
          {/* {audioUrl && <audio controls controlsList="nodownload noplaybackrate" style={{ width: '100%'}} src={audioUrl} />} */}
        </DialogContent>
      )}
    </Dialog>
  );
};

export default UpdateNoteDialog;
