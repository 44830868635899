import { useState, useCallback } from 'react';
import { useAuth} from '@clerk/clerk-react';
import { useAuth0 } from "@auth0/auth0-react";  
import requests from '../api/agnet';
import { enqueueSnackbar } from 'notistack';

const useApiRequest = ({handleResponse, handleError, successMessage = "completed SuccessFully", showSuccessSnackbar = true, showErrorSnackBar = true}) => {
  const {getAccessTokenSilently} = useAuth0();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const startLoading = () => setLoading(true);
  const endLoading = () => setLoading(false);
  const domain = 'dev-bfsjddvkdu5tp867.us.auth0.com'

  const apiRequest = useCallback(async (url,requestType = 'get', body) => {
    console.log(body)
    startLoading();
    try {
      const token = await getAccessTokenSilently({
        audience: `https://${domain}/api/v2/`,
        scope: "read:users write:users read:profile update:profile"
      });

      const response = await requests[requestType](url, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      },body);
      setData(response)
      await handleResponse(response);
      if(requestType !== 'get' && showSuccessSnackbar)
      enqueueSnackbar({
        variant:"success",
        message: successMessage
      })
    } catch (err) {
      handleError(err);
      if(showErrorSnackBar){
        enqueueSnackbar({
          variant: 'error',
          message: err?.message || "Something Went Wrong!"
        })
      }
    } finally {
      endLoading();
    }
  }, []);

  const resetResponse = () => {
    setData(null);
    setError(null);
  };

  return {
    loading,
    data,
    error,
    apiRequest, 
    resetResponse, 
  };
};

export default useApiRequest;
