// import React, { useEffect, useState } from "react";
// import PatientSections from "../../components/PatientSections";
// import CustomPhrases from "../../components/customPhrases/CustomPhrases";
// import useApiRequest from "../../hooks/useHandleRequests";
// import { useDispatch, useSelector } from "react-redux";
// import { setNoteTemplates } from "../../slices/customPatient";
// import { Box, Button, CircularProgress, Stack } from "@mui/material";
// import { Save } from "@mui/icons-material";
// import { getnoteTemplatesPostingData, tourStepsCustomNote } from "../../utils";
// import { useLocation, useNavigate } from "react-router-dom";
// import { closeTutorial } from "../../slices/tutorialSlice";

// import { driver } from "driver.js";
// import "driver.js/dist/driver.css";

// const CustomPatientTemplate = () => {
//   const isTutorialOpen = useSelector((state) => state.tutorial.isTutorialOpen);
//   const location = useLocation();
//   const [isCurrentPage, setIsCurrentPage] = useState(false);
//   useEffect(() => {
//     const match = location.pathname === "/custom-note-template";
//     if (match) {
//       setIsCurrentPage(true);
//     } else {
//       setIsCurrentPage(false);
//     }
//   }, [location]);
//   const [tour, setTour] = useState(false);
//   const startTour = () => {
//     const driverObj = driver({
//       popoverClass: "driverjs-theme",
//       showProgress: true,
//       animate: true,
//       prevBtnText: "Back",
//       nextBtnText: "Next",
//       allowClose: true,
//       steps: tourStepsCustomNote,

//       onDestroyStarted: () => {
//         setTour(false);
//         localStorage.setItem(`hasSeenTour_/custom-note-template`, "true");
//         dispatch(closeTutorial());
//         driverObj.destroy();
//       },
//     });

//     driverObj.drive();
//   };
//   useEffect(() => {
//     if (tour) {
//       startTour();
//     }
//   }, [tour]);
//   useEffect(() => {
//     const tourSeen = localStorage.getItem(`hasSeenTour_/custom-note-template`);
//     if (tourSeen === "false") {
//       setTour(true);
//     }
//   }, []);
//   useEffect(() => {
//     if (isTutorialOpen && isCurrentPage) {
//       setTour(true);
//     }
//   }, [isTutorialOpen]);
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const noteTemplates = useSelector((state) => state.noteTemplates);
//   const [edited, setEdited] = useState(false);
//   const { apiRequest: getConfigs, loading: isLoading } = useApiRequest({
//     handleResponse: (data) => {
//       if (data.msg) {
//         Object.entries(data.msg).forEach(([key, value]) => {
//           console.log("Does this come into Action", value);
//           if (!Array.isArray(value)) navigate("/on-boarding");
//         });
//       }
//       console.log("DATA>MSG", data.msg);
//       dispatch(setNoteTemplates(data.msg));
//     },
//     handleError: (err) => {
//       console.log(err);
//     },
//     showSuccessSnackbar: false,
//   });

//   const { apiRequest: postConfigs, loading: postingConfigs } = useApiRequest({
//     handleResponse: () => {},
//     handleError: () => {},
//     successMessage: "Configs has been updated",
//   });

//   useEffect(() => {
//     getConfigs("/v3/patient_prompt/");
//   }, []);

//   useEffect(() => {
//     if (edited) {
//       postConfigs(
//         "/v3/user/config",
//         "post",
//         getnoteTemplatesPostingData(noteTemplates)
//       );
//       setEdited(false);
//     }
//   }, [edited]);

//   return (
//     <React.Fragment>
//       {/* <Stack marginBottom="15px" direction="row-reverse">
//         <Button variant='contained' disabled={postingConfigs} startIcon={<Save />} onClick={()=> postConfigs('/v2/user/config','post', getnoteTemplatesPostingData(noteTemplates))}>
//           Save Changes
//         </Button>
//       </Stack> */}
//       <PatientSections postConfigs={postConfigs} setEdited={setEdited} />
//       {/* <CustomPhrases /> */}
//     </React.Fragment>
//   );
// };

// export default CustomPatientTemplate;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Button, CircularProgress, Stack } from "@mui/material";
import { Save } from "@mui/icons-material";
import { driver } from "driver.js";
import "driver.js/dist/driver.css";

import PatientSections from "../../components/PatientSections";
import CustomPhrases from "../../components/customPhrases/CustomPhrases";
import useApiRequest from "../../hooks/useHandleRequests";
import { setNoteTemplates } from "../../slices/customPatient";
import { getnoteTemplatesPostingData, tourStepsCustomNote } from "../../utils";
import { closeTutorial } from "../../slices/tutorialSlice";

const CustomPatientTemplate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const noteTemplates = useSelector((state) => state.patientTemplates);
  const isTutorialOpen = useSelector((state) => state.tutorial.isTutorialOpen);

  const [isCurrentPage, setIsCurrentPage] = useState(false);
  const [edited, setEdited] = useState(false);
  const [tour, setTour] = useState(false);

  const { apiRequest: getConfigs, loading: isLoading } = useApiRequest({
    handleResponse: (data) => {
      if (!data || !data.msg) {
        console.error("Invalid response data:", data);
        return;
      }

      // Validate the data structure before dispatching
      if (typeof data.msg === "object") {
        const hasInvalidSection = Object.entries(data.msg).some(
          ([key, value]) => {
            if (!Array.isArray(value)) {
              console.warn(`Invalid section data for ${key}:`, value);
              navigate("/on-boarding");
              return true;
            }
            return false;
          }
        );

        if (!hasInvalidSection) {
          console.log("Dispatching note templates:", data.msg);
          dispatch(setNoteTemplates(data.msg));
        }
      } else {
        console.error("Invalid msg format:", data.msg);
        navigate("/on-boarding");
      }
    },
    handleError: (err) => {
      console.error("Error fetching configs:", err);
    },
    showSuccessSnackbar: false,
  });

  const { apiRequest: postConfigs, loading: postingConfigs } = useApiRequest({
    handleResponse: () => {
      setEdited(false);
    },
    handleError: (err) => {
      console.error("Error posting configs:", err);
      setEdited(true); // Keep edited state true if save failed
    },
    successMessage: "Configs has been updated",
  });

  // Page location effect
  useEffect(() => {
    setIsCurrentPage(location.pathname === "/custom-note-template");
  }, [location]);

  // Initial data fetch
  useEffect(() => {
    getConfigs("/v3/patient_prompt/");
  }, []);

  // Handle edited state
  useEffect(() => {
    if (edited) {
      const configData = getnoteTemplatesPostingData(noteTemplates);
      postConfigs("/v3/user/config", "post", configData);
    }
  }, [edited, noteTemplates]);

  // Tour handling effects
  useEffect(() => {
    if (tour) startTour();
  }, [tour]);

  useEffect(() => {
    const tourSeen = localStorage.getItem(`hasSeenTour_/custom-note-template`);
    if (tourSeen === "false") setTour(true);
  }, []);

  useEffect(() => {
    if (isTutorialOpen && isCurrentPage) setTour(true);
  }, [isTutorialOpen, isCurrentPage]);

  const startTour = () => {
    const driverObj = driver({
      popoverClass: "driverjs-theme",
      showProgress: true,
      animate: true,
      prevBtnText: "Back",
      nextBtnText: "Next",
      allowClose: true,
      steps: tourStepsCustomNote,
      onDestroyStarted: () => {
        setTour(false);
        localStorage.setItem(`hasSeenTour_/custom-note-template`, "true");
        dispatch(closeTutorial());
        driverObj.destroy();
      },
    });

    driverObj.drive();
  };

  return (
    <React.Fragment>
      <PatientSections postConfigs={postConfigs} setEdited={setEdited} />
      {isLoading && <CircularProgress />}
    </React.Fragment>
  );
};

export default CustomPatientTemplate;
