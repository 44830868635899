// import { Cancel, Language, Menu, Search } from '@mui/icons-material'
// import { AppBar, Avatar, Box, IconButton, InputAdornment, ListItem, ListItemAvatar, ListItemText, TextField, Toolbar, Typography } from '@mui/material'
// import React, { useEffect, useState } from 'react'
// import { sideBarWidth } from '../utils'
// import CustomUserButton from './CustomUserbutton'
// import { useDispatch } from 'react-redux'
// import { updateSearch } from '../slices/notesSlice'
// import { useAuth0 } from '@auth0/auth0-react';

// const debouncDelay = 500;

// const Header = ({
//   drawerOpen,
//   setDrawerOpen,
//   setMobileOpen
// }) => {
//   const { user } = useAuth0();
//   const dispatch = useDispatch();
//   const [search, setSearch] = useState('');

//   useEffect(()=>{
//     const handler = setTimeout(()=>{
//       dispatch(updateSearch(search));
//     },debouncDelay)
//     return ()=> clearTimeout(handler)
//   },[search])
//   return (
//     <AppBar variant='outlined' sx={{
//       backgroundColor: 'white',
//       border: 'none',
//       width: { md: `calc(100% - ${drawerOpen ? sideBarWidth : 0}px)`, sm: '100%', xs: '100%' },
//       transition: '0.2s ease-in-out'
//     }} position='fixed'>
//       <Toolbar>
//         <Box width='100%' component='div' display='flex' justifyContent='space-between' alignItems='center' padding={'15px'}>
//           <Box component='div'  display='flex' columnGap='25px' alignItems='center'>
//             <Menu onClick={() => { setDrawerOpen((open) => !open) }} fontSize='large'
//               sx={{ color: '#1D2939', cursor: 'pointer', display: { xs: 'none', sm: 'none', md: 'block', lg: 'block' } }} />
//             <Menu onClick={() => { setMobileOpen((open) => !open) }} fontSize='large'
//               sx={{ color: '#1D2939', cursor: 'pointer', display: { xs: 'block', sm: 'block', md: 'none', lg: 'none' } }} />
//               <TextField
//                 size='small'
//                 sx={{
//                   backgroundColor: '#F5F6FA',
//                   width: '400px'
//                 }}
//                 variant='outlined'
//                 placeholder='Search...'
//                 value={search}
//                 onChange={(e)=> setSearch(e.target.value)}
//                 slotProps={{
//                   input: {
//                     startAdornment: (
//                       <InputAdornment position='start'>
//                         <Search />
//                       </InputAdornment>
//                     ),
//                     endAdornment: (
//                       <InputAdornment position='end'>
//                         {
//                           search && search.length > 0 &&
//                           (
//                             <IconButton onClick={()=>setSearch('')}>
//                           <Cancel />
//                         </IconButton>
//                           )
//                         }
//                       </InputAdornment>
//                     )
//                   }
//                 }}
//               />
//           </Box>
//           <Box component='div' display='flex' columnGap='25px' alignItems='center'>
//             <Box component='div' display="flex" alignItems="center" gap={2}>
//               <CustomUserButton />
//               <Typography color='#404040' fontSize="14px" fontWeight={700} marginTop="5px">
//                 {user.name}
//               </Typography>
//             </Box>
//           </Box>
//         </Box>
//       </Toolbar>
//     </AppBar>
//   )
// }

// export default Header

import { Cancel, Language, Menu, Search } from "@mui/icons-material";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  InputAdornment,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { sideBarWidth } from "../utils";
import CustomUserButton from "./CustomUserbutton";
import { useDispatch } from "react-redux";
import { updateSearch } from "../slices/notesSlice";
import { useAuth0 } from "@auth0/auth0-react";

const debouncDelay = 500;

// New AddGroupModal component
const AddGroupModal = ({ open, handleClose, onSubmit }) => {
  const [groupName, setGroupName] = useState("");
  const [adminName, setAdminName] = useState("");
  const [adminEmail, setAdminEmail] = useState("");

  const handleSubmit = () => {
    onSubmit({ groupName, adminName, adminEmail });
    handleClose();
    // Reset form
    setGroupName("");
    setAdminName("");
    setAdminEmail("");
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Add New Group</DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" gap={2} mt={2}>
          <TextField
            label="Group Name"
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
            fullWidth
          />
          <TextField
            label="Administrator Name"
            value={adminName}
            onChange={(e) => setAdminName(e.target.value)}
            fullWidth
          />
          <TextField
            label="Administrator Email"
            type="email"
            value={adminEmail}
            onChange={(e) => setAdminEmail(e.target.value)}
            fullWidth
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          disabled={!groupName || !adminName || !adminEmail}
        >
          Create Group
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const Header = ({ drawerOpen, setDrawerOpen, setMobileOpen }) => {
  const { user } = useAuth0();
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    const handler = setTimeout(() => {
      dispatch(updateSearch(search));
    }, debouncDelay);
    return () => clearTimeout(handler);
  }, [search]);

  const handleAddGroup = (groupData) => {
    // Handle the group creation here
    console.log("New group data:", groupData);
    groups.push(groupData);
    setG(groups);
    // You can add your API call or state management logic here
  };

  let groups = [
    { groupName: "A", groupAdmin: "Mohammad Ali", email: "mali@drh.ai" },
  ];

  const [g, setG] = useState(groups);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleButtonClick = (groupName) => {
    setIsModalOpen(true);
    setTimeout(() => {
      setIsModalOpen(false);
    }, 2000);
  };

  return (
    <>
      <AppBar
        variant="outlined"
        sx={{
          backgroundColor: "white",
          border: "none",
          width: {
            md: `calc(100% - ${drawerOpen ? sideBarWidth : 0}px)`,
            sm: "100%",
            xs: "100%",
          },
          transition: "0.2s ease-in-out",
        }}
        position="fixed"
      >
        <Toolbar>
          <Box
            width="100%"
            component="div"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            padding={"15px"}
          >
            <Box
              component="div"
              display="flex"
              columnGap="25px"
              alignItems="center"
            >
              <Menu
                onClick={() => {
                  setDrawerOpen((open) => !open);
                }}
                fontSize="large"
                sx={{
                  color: "#1D2939",
                  cursor: "pointer",
                  display: { xs: "none", sm: "none", md: "block", lg: "block" },
                }}
              />
              <Menu
                onClick={() => {
                  setMobileOpen((open) => !open);
                }}
                fontSize="large"
                sx={{
                  color: "#1D2939",
                  cursor: "pointer",
                  display: { xs: "block", sm: "block", md: "none", lg: "none" },
                }}
              />
              <TextField
                size="small"
                sx={{
                  backgroundColor: "#F5F6FA",
                  width: "400px",
                }}
                variant="outlined"
                placeholder="Search..."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                slotProps={{
                  input: {
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        {search && search.length > 0 && (
                          <IconButton onClick={() => setSearch("")}>
                            <Cancel />
                          </IconButton>
                        )}
                      </InputAdornment>
                    ),
                  },
                }}
              />
            </Box>
            <Box
              component="div"
              display="flex"
              columnGap="25px"
              alignItems="center"
            >
              <Box component="div" display="flex" alignItems="center" gap={2}>
                <CustomUserButton />
                <Typography
                  color="#404040"
                  fontSize="14px"
                  fontWeight={700}
                  marginTop="5px"
                >
                  {user.name}
                </Typography>
                {g.map((data) => {
                  return (
                    <Button
                      variant="contained"
                      sx={{ backgroundColor: "#404040" }}
                      onClick={() => handleButtonClick(data.groupName)}
                    >
                      {data.groupName}
                    </Button>
                  );
                })}

                <Button variant="contained" onClick={() => setModalOpen(true)}>
                  Add Group
                </Button>
              </Box>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <AddGroupModal
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
        onSubmit={handleAddGroup}
      />
      <LoaderModal open={isModalOpen} />
    </>
  );
};

const LoaderModal = ({ open }) => {
  return (
    <Dialog open={open} maxWidth="xs">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        p={4}
        sx={{ minWidth: 200 }}
      >
        <Box
          sx={{
            width: 40,
            height: 40,
            border: "4px solid",
            borderColor: "primary.main",
            borderTopColor: "transparent",
            borderRadius: "50%",
            animation: "spin 1s linear infinite",
            "@keyframes spin": {
              "0%": {
                transform: "rotate(0deg)",
              },
              "100%": {
                transform: "rotate(360deg)",
              },
            },
          }}
        />
        <Typography mt={2}>Loading...</Typography>
      </Box>
    </Dialog>
  );
};

export default Header;
