import React, { useEffect, useRef, useState } from "react";
import { Card, CardContent, Typography, IconButton, Box } from "@mui/material";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import useApiRequest from "../../hooks/useHandleRequests";

const PatientPrompt = () => {
  const [content, setContent] = useState("");
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderlined, setIsUnderlined] = useState(false);
  const textRef = useRef(null);
  const debounceTimeout = useRef(null);

  const { apiRequest: getNotePrompt, loading: loadingNotePrompt } =
    useApiRequest({
      handleError: (err) => console.log(err),
      handleResponse: (data) => {
        setContent(data.msg || ""); // Safely access the message
      },
      showSuccessSnackbar: false,
    });

  const { apiRequest: updateNotePrompt, loading: updatingNotePrompt } =
    useApiRequest({
      handleResponse: () => {},
      handleError: () => console.error("Error updating patient prompt"),
      successMessage: "Patient-Prompt has been updated",
    });

  // Fetch initial content
  useEffect(() => {
    getNotePrompt(`/v3/patient_prompt`);
  }, []);

  // Apply text style
  const applyStyle = (command) => {
    document.execCommand(command, false, null);
    textRef.current.focus();
    updateActiveStyles();
  };

  const updateActiveStyles = () => {
    setIsBold(document.queryCommandState("bold"));
    setIsItalic(document.queryCommandState("italic"));
    setIsUnderlined(document.queryCommandState("underline"));
  };

  const handleContentChange = () => {
    const newContent = textRef.current.innerText;
    setContent(newContent);

    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    debounceTimeout.current = setTimeout(() => {
      updateNotePrompt(`/v3/patient_prompt`, "put", { prompt: newContent });
    }, 500);
  };

  useEffect(() => {
    if (textRef.current && textRef.current.innerText !== content) {
      textRef.current.innerText = content;
    }
  }, [content]);

  // Track selection changes for styles
  useEffect(() => {
    const handleSelectionChange = () => updateActiveStyles();
    document.addEventListener("selectionchange", handleSelectionChange);
    return () =>
      document.removeEventListener("selectionchange", handleSelectionChange);
  }, []);

  return (
    <>
      <Box>
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            fontSize: "32px",
            textAlign: "start",
            mb: 3,
            color: "#202224",
          }}
        >
          Patient Experience Prompt
        </Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        bgcolor="#f5f5f5"
      >
        <Card sx={{ maxWidth: 600, width: "100%", padding: 2, boxShadow: 3 }}>
          <CardContent>
            <Typography
              variant="h6"
              gutterBottom
              color="#202224"
              fontWeight="bold"
              fontSize="18px"
            >
              Feel free to enter your prompt here.
            </Typography>

            <Box display="flex" justifyContent="flex-end" mb={1}>
              <IconButton
                onClick={() => applyStyle("bold")}
                aria-label="Bold"
                color={isBold ? "primary" : "default"}
              >
                <FormatBoldIcon />
              </IconButton>
              <IconButton
                onClick={() => applyStyle("italic")}
                aria-label="Italic"
                color={isItalic ? "primary" : "default"}
              >
                <FormatItalicIcon />
              </IconButton>
              <IconButton
                onClick={() => applyStyle("underline")}
                aria-label="Underline"
                color={isUnderlined ? "primary" : "default"}
              >
                <FormatUnderlinedIcon />
              </IconButton>
            </Box>

            <Box
              contentEditable
              ref={textRef}
              onInput={handleContentChange}
              sx={{
                minHeight: "150px",
                padding: 2,
                border: "1px solid #ccc",
                borderRadius: "8px",
                fontSize: "1rem",
                fontWeight: 400,
                overflow: "auto",
                whiteSpace: "pre-wrap", // Keep line breaks
                wordWrap: "break-word",
                "&:focus": { outline: "none" },
              }}
              placeholder="Type your prompt here..."
            />
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default PatientPrompt;
